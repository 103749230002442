import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import cartImage from "assets/img/products/Doodle cart.png";
import { useCart } from "components/CartContext/CartContext";
import { IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const CartModal = ({ isOpen, onClose }) => {
  const { cartItems, adjustCartQuantity, removeFromCart } = useCart();
  const navigate = useNavigate();
  const [userLevel, setUserLevel] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.userId) {
      setUserLevel(userData.level);
    }
  }, []);

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const price =
        userLevel === "Unknown" ? item.mrp : item.discountPrice;
      return total + price * item.quantity;
    }, 0);
  };

  const bg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const itemBg = useColorModeValue("white", "gray.600");
  const borderColor = useColorModeValue("gray.300", "white");

  const hoverStyle = {
    boxShadow: "lg",
    bg: useColorModeValue("gray.50", "gray.700"),
  };

  const handleCheckout = () => {
    if (cartItems.length === 0) return;

    onClose();
    navigate("/user/payment-process", { state: { cartItems } });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "xl" }}>
      <ModalOverlay />
      <ModalContent bg={bg} borderRadius="lg" boxShadow="xl">
        <ModalHeader>
          <Flex justify="space-between" align="center">
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
              Your Cart
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton color={textColor} />
        <ModalBody>
          <Box maxHeight="400px" overflowY="auto">
            {cartItems.length === 0 ? (
              <Flex
                justify="center"
                align="center"
                flexDirection="column"
                height="200px"
              >
                <Image src={cartImage} alt="Empty Cart" width="100px" />
                <Text fontSize="xl" color={textColor} mt={4}>
                  Your cart is empty.
                </Text>
              </Flex>
            ) : (
              cartItems.map((item) => {
                const price =
                  userLevel === "Unknown" ? item.mrp : item.discountPrice;

                return (
                  <Flex
                    key={item.productCode}
                    bg={itemBg}
                    border="1px"
                    borderColor={borderColor}
                    borderRadius="md"
                    p={4}
                    mb={2}
                    align="center"
                    boxShadow="sm"
                    _hover={hoverStyle}
                    direction={{ base: "column", md: "row" }} // Mobile view: stacked, desktop view: side-by-side
                    gap={{ base: 0, md: 4 }} // Gap between elements
                  >
                    <Image
                      src={item.productImage}
                      alt={item.productName}
                      boxSize={{ base: "70px", md: "50px" }} // Larger image on mobile
                      objectFit="cover"
                      borderRadius="md"
                      mb={{ base: 3, md: 0 }} // Bottom margin on mobile
                    />
                    <Box
                      ml={{ base: 0, md: 3 }}
                      flex="1"
                      textAlign={{ base: "center", md: "left" }} // Center text on mobile, left on desktop
                      mb={{ base: 3, md: 0 }} // Bottom margin on mobile
                    >
                      <Text fontWeight="bold" color={textColor}>
                        {item.productName}
                      </Text>
                      <Text color={textColor}>
                        <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>
                          ₹&nbsp;
                        </span>
                        {price} x {item.quantity}
                      </Text>
                    </Box>
                    <Flex
                      align="center"
                      direction={"row"} // Row on mobile, column on desktop
                      justify="center"
                      mb={{ base: 3, md: 0 }} // Bottom margin on mobile
                      gap={2}
                    >
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => adjustCartQuantity(item.productCode, 'decrease')}
                        _hover={{ bg: "gray.300" }}
                        mr={{ base: 2, md: 0 }} // Margin for mobile view
                      >
                        -
                      </Button>
                      <Text color={textColor} mx={{ base: 2, md: 0 }}>
                        {item.quantity}
                      </Text>
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => adjustCartQuantity(item.productCode, 'increase')}
                        _hover={{ bg: "gray.300" }}
                        ml={{ base: 2, md: 0 }} // Margin for mobile view
                      >
                        +
                      </Button>
                    </Flex>
                    <Icon
                      as={IoTrashOutline}
                      cursor="pointer"
                      onClick={() => removeFromCart(item.productCode)}
                      color="red.500"
                      _hover={{ color: "red.700" }}
                      mt={{ base: 2, md: 0 }} // Margin for mobile view
                    />
                  </Flex>
                );
              })
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Text fontSize="xl" fontWeight="bold" color={textColor} mr={2}>
            Total: <span style={{ fontFamily: "'Noto Sans', sans-serif", fontSize: "20px" }}>₹&nbsp;</span>
            {calculateTotal()}.00
          </Text>
          <Button
            colorScheme="green"
            variant="solid"
            _hover={{ bg: "green.600" }}
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CartModal;
