// statusLabels.js

const labels = {
    "VERIFICATION_PENDING": "Verification Pending",
    "VERIFICATION_REQUESTED": "Verification Requested",
    "VERIFICATION_APPROVED": "Verification Approved",
    "VERIFICATION_REJECTED": "Verification Rejected",
    "PLACED": "Placed",
    "PAYMENT_PENDING": "Payment Pending ",
    "PAYMENT_REVIEW": "Payment Review",
    "READY_FOR_PICKUP": "Ready for Pickup",
    "PICKED_UP":"Picked Up",
    "CANCELED":"Canceled",
    "PAYMENT_VERIFIED":"Payment verified",
    "PAYMENT_FAILED":"Payment Failed"
  };
  
  export default labels;
  