import React from "react";
import { useLocation } from "react-router-dom";
import {
  Image,
  Box,
  Text,
  Heading,
  Divider,
  VStack,
  Stack,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";

const OrderSummary = () => {
  const location = useLocation();
  const { product, quantity, paymentMethod, cartItems } = location.state || {};

  // Function to calculate the total for cart items
  const calculateTotalForCart = () => {
    return cartItems.reduce(
      (total, item) => total + item.discountPrice * item.quantity,
      0
    );
  };

  // Dynamic text color based on theme
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  return (
    <Box
      padding={{ base: "0px", md: "40px" }}
      paddingTop={{ base: "80px", md: "80px" }}
      width="100%" // Ensures full-width layout
      maxWidth={{ base: "100%", md: "100%" }} // Full-width on both mobile and desktop
      margin="0 auto"
      minHeight="100vh"
      color={textColor}
    >
      <Box padding={{ base: "20px", md: "40px" }} borderRadius="lg" boxShadow="lg">
        <Heading as="h2" size="xl" marginBottom="30px" textAlign="center">
          Order Summary
        </Heading>

        <VStack spacing={6} align="stretch">
          {/* Individual product summary */}
          {product && (
            <Box padding="20px" borderRadius="md" boxShadow="md">
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={4}
                align="flex-start"
              >
                <Image
                  src={product.productImage}
                  alt={product.productName}
                  boxSize={{ base: "80px", md: "120px" }}
                  objectFit="cover"
                  borderRadius="md"
                />
                <Stack spacing={1} width="100%">
                  <Heading as="h4" size={{ base: "sm", md: "md" }}>
                    {product.productName}
                  </Heading>
                  <Text fontSize={{ base: "sm", md: "md" }}>Quantity: {quantity}</Text>
                  <Text fontWeight="bold" fontSize={{ base: "md", md: "lg" }}>
                    Total: ₹{product.discountPrice * quantity}.00
                  </Text>
                </Stack>
              </Stack>
            </Box>
          )}

          {/* Cart items summary */}
          {cartItems && (
            <Box padding="20px" borderRadius="md" boxShadow="md">
              <Text fontSize={{ base: "md", md: "lg" }} fontWeight="semibold" marginBottom={3}>
                Products in your Cart:
              </Text>

              {/* Scrollable container for cart items */}
              <Box
                height="400px"
                overflowY="auto"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="md"
                padding="10px"
              >
                {cartItems.map((item, index) => (
                  <Box
                    key={index}
                    padding="15px"
                    marginBottom="10px"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="md"
                    boxShadow="sm"
                    display="flex"
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={4}
                      align="center"
                      width="100%"
                    >
                      <Image
                        src={item.productImage}
                        alt={item.productName}
                        boxSize={{ base: "60px", md: "70px" }}
                        objectFit="cover"
                        borderRadius="md"
                      />
                      <Box>
                        <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                          {item.productName}
                        </Text>
                        <Text fontSize={{ base: "xs", md: "sm" }}>Quantity: {item.quantity}</Text>
                      </Box>
                      <Text
                        fontWeight="bold"
                        fontSize={{ base: "sm", md: "md" }}
                        textAlign={{ base: "left", md: "right" }}
                        width="100%"
                      >
                        ₹{item.discountPrice * item.quantity}.00
                      </Text>
                    </Stack>
                  </Box>
                ))}
              </Box>

              <Divider marginY={4} />
              <Text fontSize="xl" fontWeight="bold">
                Total: ₹{calculateTotalForCart()}.00
              </Text>
            </Box>
          )}

          {/* Payment method section */}
          <Box padding="20px" borderRadius="md" boxShadow="md">
            <Heading as="h6" size="md" marginBottom={2}>
              Payment Information
            </Heading>
            <Text>
              <Badge colorScheme="green" fontSize="lg">
                {paymentMethod}
              </Badge>
            </Text>
          </Box>
        </VStack>

        {/* Thank you message */}
        <Text
          marginTop="30px"
          fontSize={{ base: "md", md: "lg" }}
          fontWeight="bold"
          textAlign="center"
          color="green.500"
        >
          Thank you for your purchase! Your order has been placed successfully.
        </Text>
      </Box>
    </Box>
  );
};

export default OrderSummary;
