import {
  Box,
  Flex,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useEffect, useState } from "react";
import config from '../../../../config.json';
const isLocal = config.isLocal;

export default function EarningsCard(props) {
  const { ...rest } = props;
  const [totalIncome, setTotalIncome] = useState(0);

  const goal = 100000;

  useEffect(() => {
    const fetchIncomeData = async () => {
      try {
        if (!isLocal) {
          const apiUrl = `${config.API}/user/income/get?type=total`;
          const token = localStorage.getItem("authToken");

          const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch income data: ${response.status}`);
          }

          const data = await response.json();
          setTotalIncome(data.totalIncome || 0); 
        }
      } catch (error) {
        console.error("Error fetching income data:", error);
      }
    };

    fetchIncomeData();
  }, []);


  const textColor = useColorModeValue("secondaryGray.900", "#32B44A");
  const textColorSecondary = useColorModeValue("secondaryGray.700", "white");
  const numberColor = useColorModeValue("secondaryGray.900", "white");

  // Calculate the percentage of progress
  const progressPercentage = Math.min((totalIncome / goal) * 100, 100);

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      {/* Earnings and Bonuses Section */}
      <Flex
        w="100%"
        justifyContent="space-between"
        p={{ base: "10px", md: "20px" }}
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* Earnings Section */}
        <Flex flexDirection="column" align="start" w={{ base: "100%", md: "70%" }}>
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} fontWeight="500">
            Your Earnings
          </Text>
          <Text color={numberColor} fontSize={{ base: "30px", md: "40px" }} fontWeight="700">
            <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>{totalIncome?.toFixed(2) || "0.00"}
          </Text>
          <Text color={textColorSecondary} fontSize={{ base: "sm", md: "md" }} fontWeight="400">
            Earnings past 30 days
          </Text>
          {/* Segmented Progress Bar based on totalIncome */}
          <Box position="relative" w="100%" mt="20px">
            <Flex justify="space-between" align="center" w="100%">
              {[...Array(5)].map((_, index) => (
                <Box
                  key={index}
                  h="10px"
                  w={`${100 / 5}%`}
                  bg={index < Math.ceil(progressPercentage / 20) ? "green.400" : "gray.300"}
                  borderRadius="6px"
                />
              ))}
            </Flex>
            <Text
              color={textColorSecondary}
              fontSize="sm"
              mt="10px"
              w="100%"
              textAlign="end"
              pr={{ base: "20px", md: "80px" }}
            >
              <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>{totalIncome?.toFixed(2) || "0.00"} of <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>{goal}
            </Text>
          </Box>
        </Flex>

        {/* Chart Section */}
        {/* Uncomment and implement your chart component as needed */}
        {/* <Box minH="150px" maxH="200px" width="100%" mt="20px">
          <LineChart
            chartData={lineChartDataTotalSpent}
            chartOptions={lineChartOptionsTotalSpent}
            width="100%"
            height="100%"
          />
        </Box> */}

        {/* Bonuses Section */}
        {/* Uncomment and implement your bonuses section as needed */}
        {/* <Flex
          flexDirection="column"
          align={{ base: "start", md: "flex-end" }}
          w={{ base: "100%", md: "30%" }}
          mt={{ base: "20px", md: "0px" }}
        >
          <Box bg={boxBg} borderRadius="8px" p="15px" mb="10px" w="100%" h="30%">
            <Text color={textColorSecondary} fontSize={{ base: "sm", md: "md" }} fontWeight="500">
              Referral Bonus
            </Text>
            <Text color={textColor} fontSize={{ base: "md", md: "lg" }} fontWeight="700">
              ₹1,405
            </Text>
          </Box>
          <Box bg={boxBg} borderRadius="8px" p="15px" mb="10px" w="100%" h="30%">
            <Text color={textColorSecondary} fontSize={{ base: "sm", md: "md" }} fontWeight="500">
              Level Bonus
            </Text>
            <Text color={textColor} fontSize={{ base: "md", md: "lg" }} fontWeight="700">
              ₹100
            </Text>
          </Box>
          <Box bg={boxBg} borderRadius="8px" p="15px" w="100%" h="30%">
            <Text color={textColorSecondary} fontSize={{ base: "sm", md: "md" }} fontWeight="500">
              Repurchase Bonus
            </Text>
            <Text color={textColor} fontSize={{ base: "md", md: "lg" }} fontWeight="700">
              ₹585
            </Text>
          </Box>
        </Flex> */}
      </Flex>
    </Card>
  );
}
