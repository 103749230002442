import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import illustration from "assets/img/auth/auth.png";
import DefaultAuth from "layouts/auth/Defaultsl";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import config from "../../config.json";

function Register() {
  const textColor = useColorModeValue("#32B44A", "#32B44A");
  const textColorSecondary = useColorModeValue("navy.700", "white");
  const textColorDetails = useColorModeValue("navy.700", "white");
  const textColorBrand = useColorModeValue("#33cc33", "#29a329");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [userId, setUserId] = useState(localStorage.getItem("userId") || "");
  // const [userId] = useState(storedUserId || "");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profession, setProfession] = useState("");


  const toast = useToast();

  const handleRegister = async () => {
    if (!fullName || !phoneNumber || !profession) {
      toast({
        title: "Missing Fields",
        description: "Please fill out all required fields.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
  
    const data = {
      fullName,
      phoneNumber,
      email,
      referralId: userId,
      profession,
    };
  
    const token = localStorage.getItem("authToken");
  
    try {
      const response = await fetch(`${config.API}/user/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        const errorText = await response.text(); 
        throw new Error(errorText || "Registration failed.");
      }
  
      const contentType = response.headers.get("Content-Type");
  
      let result;
      if (contentType && contentType.includes("application/json")) {
        result = await response.json();
      } else {
        const responseText = await response.text();
        result = { message: responseText }; 
      }
  
      if (result.message) {
        const regex = /userId : (\S+) & password : (\S+)/; 
        const match = result.message.match(regex);
  
        if (match) {
          const userId = match[1];
          const password = match[2];
  
          toast({
            title: "Registration Successful, Please make sure to note them down for future reference. ",
            description: `Your account has been created successfully. User ID: ${userId} and Password: ${password}.`,
            status: "success",
            duration: 8000,  
            isClosable: true,
          });
  
          setFullName("");
          setEmail("");
          setPhoneNumber("");
          setProfession("");
        } else {
          toast({
            title: "Registration Failed",
            description: "User ID or Password was not found in the response. Please check the response.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Registration Failed",
          description: result?.message || "An error occurred during registration.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error:", error);
  
      toast({
        title: "Error",
        description: error.message || "An error occurred while registering. Please try again later.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };


  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "20px", md: "24vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Register
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Fill the details below to create a new account!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Referral ID<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              value={userId} // Make sure `userId` is managed using a state
              onChange={(e) => setUserId(e.target.value)} // Add this to handle changes
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              mb="24px"
              fontWeight="500"
              size="lg"
            />


            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Full Name<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              mb="24px"
              fontWeight="500"
              size="lg"
            />

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email (Optional)
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              mb="24px"
              fontWeight="500"
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Profession<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Profession"
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
              mb="24px"
              fontWeight="500"
              size="lg"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Phone Number<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              mb="24px"
              fontWeight="500"
              size="lg"
            />

            <Button
              backgroundColor="#32B44A"
              fontSize="sm"
              fontWeight="500"
              w="100%"
              h="50px"
              mb="24px"
              _hover={{
                backgroundColor: "#33cc33",
              }}
              _active={{
                backgroundColor: "#29a329",
              }}
              _focus={{
                boxShadow: "none",
              }}
              onClick={handleRegister}
            >
              Register
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
            mb="40px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Already have an account?
              <NavLink to="/user/login">
                <Text color={textColorBrand} as="span" ms="5px" fontWeight="500" >
                  Sign In Here
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Register;
