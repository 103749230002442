// UserLayout.js
import { Box, Portal, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin'; // Ensure this path is correct
import Navbar from 'components/navbar/NavbarAdmin'; // Ensure this path is correct
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import routes from 'routes.js'; // Ensure the route file includes '/user' paths

export default function UserLayout(props) {
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const location = useLocation(); // Get current route


  const getRoute = () => {
    return window.location.pathname !== '/user/full-screen-maps';

  };

  // Conditionally hide the navbar if on the /register route

  // Check if the current route is '/user/register'
  const isRegisterRoute = location.pathname === '/user/register';
  const isLoginRoute = location.pathname === '/user/login';
  const isDashboardRoute = location.pathname === '/user/dashboard';
  const isNoFooterRoute = isRegisterRoute || isLoginRoute; // Added condition for routes without footer
  const isResetPasswordRoute = location.pathname === '/user/resetPassword';
  const isNoNavbarRoute = isRegisterRoute || isLoginRoute || isResetPasswordRoute; // Combine all conditions
  const isNoSidebarRoute = isNoNavbarRoute; // Same condition for hiding the sidebar

  // Determine the width of the content area
  const contentWidth = isRegisterRoute || isLoginRoute || isResetPasswordRoute     ? '100%' : { base: '100%', xl: 'calc(100% - 290px)' };


  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };



  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === '/user') {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      }
      return null;
    });
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();

  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          {/* Conditionally render Sidebar */}
          {!(isRegisterRoute || isLoginRoute || isResetPasswordRoute) && <Sidebar routes={routes} display="block" {...rest} />}
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={contentWidth}
            maxWidth={contentWidth}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            {/* Conditionally render Navbar */}
            {!(isRegisterRoute || isLoginRoute || isResetPasswordRoute) && (
              <Portal>
                <Box>
                  <Navbar
                    onOpen={onOpen}
                    logoText={'User Dashboard'}
                    brandText={getActiveRoute(routes)}
                    secondary={getActiveNavbar(routes)}
                    message={getActiveNavbarText(routes)}
                    fixed={fixed}
                    {...rest}
                  />
                </Box>
              </Portal>
            )}
            {getRoute() ? (
              <Box
                mx="auto"
                p={{ base: '20px', md: '30px' }}
                pe="20px"
                minH="100vh"
                pt="50px"
              >
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/user/default" replace />}
                  />
                </Routes>
                <Outlet /> {/* This will render the matched nested route component */}
              </Box>
            ) : null}
            <Box>
              {/* Only show footer on the Dashboard route */}
              {isDashboardRoute && <Footer />} {/* Updated condition */}
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
