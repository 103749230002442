import {
    Box,
    Button,
    Center,
    Circle, Divider,
    Flex,
    FormControl,
    Grid,
    Image,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    Tooltip,
    useColorModeValue,
    useDisclosure,
    useToast,
    VStack
} from "@chakra-ui/react";
import Invoice from "components/invoice/Invoice";
import labels from "components/label/lables";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import qrCodeImage from "../../assets/img/qr.png";
import config from '../../config.json';

// import { saveAs } from "file-saver"; 

const isLocal = config.isLocal;
const upiId = "kavipriya.kousalya2004-1@oksbi";



const orderStatuses = [
    { id: "PLACED", label: "Placed", color: "green.400" },
    { id: "CANCELED", label: "Cancelled", color: "red.500" },
    { id: "PAYMENT_PENDING", label: "Payment Pending", color: "orange.400" },
    { id: "PAYMENT_REVIEW", label: "Payment Review", color: "yellow.400" },
    { id: "PAYMENT_FAILED", label: "Payment Failed", color: "red.400" },
    { id: "PAYMENT_VERIFIED", label: "Payment Verified", color: "blue.400" },
    { id: "READY_FOR_PICKUP", label: "Ready for Pickup", color: "purple.400" },
    { id: "PICKED_UP", label: "Picked Up", color: "teal.400" },
];

export function VerticalStatusTracker({ statuses, currentStatus }) {
    const currentIndex = statuses.findIndex((status) => status.id === currentStatus);

    return (
        <Flex direction="row" align="center" justify="flex-start" mt={5} mb={5}>
            {/* Left column for circles */}
            <VStack spacing={5} align="center">
                {statuses.map((status, index) => (
                    <Box key={status.id} position="relative">
                        {/* Circle with status color */}
                        <Circle
                            size="30px"
                            bg={index <= currentIndex ? status.color : "gray.300"}
                            border="2px solid"
                            borderColor={index <= currentIndex ? status.color : "gray.300"}
                        />

                        {/* Line connecting circles */}
                        {index < statuses.length - 1 && (
                            <Divider
                                orientation="vertical"
                                borderWidth="2px"
                                borderColor={index < currentIndex ? status.color : "gray.300"}
                                h="40px"
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translateX(-50%)"
                            />
                        )}
                    </Box>
                ))}
            </VStack>

            {/* Right column for text labels */}
            <VStack spacing={6} align="flex-start" ml={5} >
                {statuses.map((status, index) => (
                    <Text
                        key={status.id}
                        fontSize="lg"
                        fontWeight={index === currentIndex ? "bold" : "normal"}
                        color={index <= currentIndex ? status.color : "gray.500"}
                    >
                        {status.label}
                    </Text>
                ))}
            </VStack>
        </Flex>

    );
}
export const fetchOrderDetails = async (orderId, setOrderDetails, setLoading) => {
    setLoading(true);
    if (isLocal) {
        try {
            const response = await fetch('/data/productDetail.json');
            if (!response.ok) throw new Error('Failed to fetch orders');
            const data = await response.json();
            setOrderDetails(data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    } else {
        const token = localStorage.getItem("authToken");
        if (!orderId) {
            console.error("Error: orderId is undefined");
            setLoading(false);
            return;
        }
        try {
            const response = await fetch(`${config.API}/order/get/${orderId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("authToken");
                    window.location.href = "/user/login";
                    return;
                }
                throw new Error('Failed to fetch orders from the API');
            }
            const data = await response.json();
            setOrderDetails(data);
        }
        catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    }
};


function Purchase() {
    const { orderId } = useParams();
    const toast = useToast();
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderDetails, setOrderDetails] = useState({
        items: [],
        orderId: null,
        userId: null,
        weekId: null,
        orderStatus: null,
    });
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const pageSize = 5;

    // Calculate pagination values
    const indexOfLastOrder = currentPage * pageSize;
    const indexOfFirstOrder = indexOfLastOrder - pageSize;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(orders.length / pageSize);
    const [isCanceling, setIsCanceling] = useState(false);

    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [fileName, setFileName] = useState("");


    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = qrCodeImage;
        link.download = "QRCode.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCancel = () => {
        setFileName("");
        setIsFileUploaded(false);
        document.getElementById("screenshotUpload").value = "";
    };

    useEffect(() => {
        if (orderId) {
            fetchOrderDetails(orderId, setOrderDetails, setLoading);
        }
    }, [orderId]);

    const updateOrderStatus = async (orderId, newStatus) => {
        setIsCanceling(true);
        const token = localStorage.getItem("authToken");
        if (!orderId || !newStatus) {
            console.error("Error: missing orderId or status");
            return;
        }
        try {
            const response = await fetch(`${config.API}/order/update/status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderId: orderId,
                    status: newStatus,
                }),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("authToken");
                    window.location.href = "/user/login";
                    return;
                }
                throw new Error('Failed to update order status');
            }

            const updatedOrder = await response.json();

            setOrderDetails((prevDetails) => {
                const updatedDetails = {
                    ...prevDetails,
                    orderStatus: updatedOrder.status
                };
                console.log('Updated Order Details:', updatedDetails);
                return updatedDetails;
            });
        } catch (error) {
            console.error('Error updating order status:', error);
        } finally {
            setIsCanceling(false);
        }
    };

    const textColor = useColorModeValue("gray.800", "gray.200");
    const amountColor = useColorModeValue("gray.800", "gray.200");
    const bgColor = useColorModeValue("gray.100", "navy.800");
    const boxBgColor = useColorModeValue("white", "navy.800");
    const headerColor = useColorModeValue("#32B44A", "#32B44A");
    const buttonColor = useColorModeValue("#32B44A", "#32B44A");
    const modalBg = useColorModeValue("white", "gray.700");
    const modalTextColor = useColorModeValue("teal.600", "teal.400");
    const linkColor = useColorModeValue("green.500", "green.300");
    const linkHoverColor = useColorModeValue("green.700", "green.500");
    const buttonBorderColor = useColorModeValue("green.700", "green.600");
    const buttonHoverBorderColor = useColorModeValue("green.800", "green.700");

    const renderPageButtons = () => {
        const pageButtons = [];
        if (totalPages <= 3) {
            for (let i = 1; i <= totalPages; i++) {
                pageButtons.push(
                    <Button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        variant={i === currentPage ? "solid" : "outline"}
                        colorScheme="green"
                        color={i === currentPage ? "white" : buttonColor}
                        mx={1}
                    >
                        {i}
                    </Button>
                );
            }
        } else {
            if (currentPage > 2) {
                pageButtons.push(
                    <Button
                        key={1}
                        onClick={() => handlePageChange(1)}
                        variant="outline"
                        colorScheme="green"
                        mx={1}
                    >
                        1
                    </Button>
                );
                if (currentPage > 3) {
                    pageButtons.push(
                        <Text key="start-ellipsis" mx={1}>
                            ...
                        </Text>
                    );
                }
            }
            for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages, currentPage + 1); i++) {
                pageButtons.push(
                    <Button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        variant={i === currentPage ? "solid" : "outline"}
                        colorScheme="green"
                        color={i === currentPage ? "white" : buttonColor}
                        mx={1}
                    >
                        {i}
                    </Button>
                );
            }
            if (currentPage < totalPages - 1) {
                if (currentPage < totalPages - 2) {
                    pageButtons.push(
                        <Text key="end-ellipsis" mx={1}>
                            ...
                        </Text>
                    );
                }
                pageButtons.push(
                    <Button
                        key={totalPages}
                        onClick={() => handlePageChange(totalPages)}
                        variant="outline"
                        colorScheme="green"
                        mx={1}
                    >
                        {totalPages}
                    </Button>
                );
            }
        }
        return pageButtons;
    };

    const [uploadedStatus, setUploadedStatus] = useState(null);

    const handleSubmit = async () => {
        const fileInput = document.getElementById("screenshotUpload");
        const file = fileInput.files[0];

        if (!file) {
            toast({
                title: "No screenshot uploaded",
                description: "Please upload a screenshot before submitting.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("screenshot", file);

        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(`${config.API}/payment/uploadScreenshot/${orderId}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to upload screenshot");
            }

            const data = await response.json();
            console.log("Screenshot uploaded successfully:", data);
            setOrderDetails((prevState) => ({
                ...prevState,
                orderStatus: data.status,
            }));
            setUploadedStatus(data.status);
            toast({
                title: "Screenshot Uploaded",
                description: "Your screenshot has been uploaded successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error uploading screenshot:", error);
            alert("Error uploading screenshot.");
        } finally {
            setLoading(false);
        }
    };

    const fileNameColor = useColorModeValue("black", "white");

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.name !== fileName) {
            setFileName(file.name);
            setIsFileUploaded(true);
        }
    };

    const handleButtonClick = async () => {
        if (!isFileUploaded) {
            const fileInput = document.getElementById('screenshotUpload');
            if (fileInput) {
                fileInput.click();
            }
        } else {
            handleSubmit();
        }
    };

    return (
        <Box p={5} bg={bgColor} minHeight="100vh" mt="80px">
            {loading ? (
                <Center minHeight="50vh">
                    <Spinner thickness="4px" speed="0.75s" color="#32B44A" size="xl" />
                </Center>
            ) : orderDetails.items.length > 0 ? (
                <>
                    {/* Order Summary */}
                    <Box bg={headerColor} borderTopRadius="12px" p="10px" mb="20px" boxShadow="md">
                        <Flex
                            justify="space-between"
                            align={{ base: 'flex-start', md: 'center' }}
                            direction={{ base: 'column', md: 'row' }}
                            textAlign={{ base: 'left', md: 'left' }}
                            wrap="wrap"
                            gap="10px"
                        >
                            <Flex direction="row" align="center" gap={{ base: '5px', md: '0' }}>
                                <Text fontWeight="bold" color={amountColor} minWidth={{ base: '120px', lg: '80px' }}>
                                    Order Id
                                </Text>
                                <Text>:  {orderDetails.orderId}</Text>
                            </Flex>
                            <Flex direction="row" align="center" gap={{ base: '5px', md: '0' }}>
                                <Text fontWeight="bold" color={amountColor} minWidth={{ base: '120px', lg: '80px' }}>
                                    Total Price
                                </Text>
                                <Text>:  {orderDetails.totalPrice}</Text>
                            </Flex>
                            <Flex direction="row" align="center" gap={{ base: '5px', md: '0' }} >
                                <Text fontWeight="bold" color={amountColor} minWidth={{ base: '120px', lg: '80px' }}>
                                    Total BV
                                </Text>
                                <Text>:  {orderDetails.totalBv}</Text>
                            </Flex>
                            <Flex direction="row" align="center" gap={{ base: '5px', md: '0' }} >
                                <Text fontWeight="bold" color={amountColor} minWidth={{ base: '120px', lg: '80px' }}>
                                    Order Status
                                </Text>
                                <Text>: {labels[orderDetails.orderStatus]}</Text>
                            </Flex>
                            <Flex direction="row" align="center" gap={{ base: '5px', md: '0' }} >
                                <Text fontWeight="bold" color={amountColor} minWidth={{ base: '120px', lg: '80px' }}>
                                    Payment Type
                                </Text>
                                <Text>: {orderDetails.paymentType || 'N/A'}</Text>
                            </Flex>
                        </Flex>
                    </Box>

                    {orderDetails.items.map((item, index) => (
                        <Box
                            key={index}
                            borderWidth="1px"
                            borderRadius="12px"
                            mb="20px"
                            bg={boxBgColor}
                            p={{ base: 3, md: 5 }}
                            boxShadow="md"
                        >
                            <Flex direction={{ base: 'column', md: 'row' }} align="flex-start" p="15px">
                                <Image
                                    src={item.product.productImage}
                                    alt={item.product.name}
                                    boxSize={{ base: '100px', md: '150px' }}
                                    mr={{ base: 0, md: 10 }}
                                    mb={{ base: 3, md: 0 }}
                                />
                                <Box flex="1">
                                    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={2} color={textColor}>
                                        {/* First Column (Product Code and Product Name) */}
                                        <Box>
                                            <Grid templateColumns="120px 1fr" gap={1}>
                                                <Text fontWeight="bold">Product Code</Text>
                                                <Text>: {item.product.productCode}</Text>
                                            </Grid>
                                            <Grid templateColumns="120px 1fr" gap={1}>
                                                <Text fontWeight="bold">Product Name</Text>
                                                <Tooltip label={item.product.name} hasArrow>
                                                    <Text maxWidth="300px" noOfLines={2} overflow="hidden" whiteSpace="normal">
                                                        : {item.product.name}
                                                    </Text>
                                                </Tooltip>
                                            </Grid>
                                        </Box>

                                        {/* Second Column (BV, Total Amount, Payment Type) */}
                                        <Box>
                                            <Grid templateColumns="120px 1fr" gap={2}>
                                                <Text fontWeight="bold">BV</Text>
                                                <Text>: {item.product.bv} × {item.quantity} ={' '}
                                                    {item.product.bv * item.quantity}
                                                </Text>
                                            </Grid>

                                            <Grid templateColumns="120px 1fr" gap={2}>
                                                <Text fontWeight="bold">Total Amount</Text>
                                                <Text>
                                                    : <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹</span>{' '}
                                                    {item.product.mrp} × {item.quantity} ={' '}
                                                    <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹</span>{' '}
                                                    {item.product.mrp * item.quantity}
                                                </Text>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Flex>
                        </Box>
                    ))}



                    {/* Pagination Controls */}
                    {orderDetails.items.length > 4 && (
                        <>
                            <Flex justify="center" align="center" mt={4}>
                                <Button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    isDisabled={currentPage === 1}
                                    variant="outline"
                                    colorScheme="green"
                                    mr={2}
                                >
                                    Previous
                                </Button>

                                {renderPageButtons()}

                                <Button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    isDisabled={currentPage === totalPages}
                                    variant="outline"
                                    colorScheme="green"
                                    ml={2}
                                >
                                    Next
                                </Button>
                            </Flex>
                            <Center mt={2}>
                                <Text align="center" mt="2" color="gray.500">
                                    Page {currentPage} of {totalPages}
                                </Text>
                            </Center>
                        </>
                    )}

                    {/* Order Status Section */}
                    {orderDetails.orderStatus && (
                        <Box mt={4} p={3} mb={4} borderWidth="1px" borderRadius="8px" bg={boxBgColor}>
                            <Grid templateColumns="auto 1fr" alignItems="center" gap={2}>
                                <Text fontWeight="bold">
                                    Order Status:
                                </Text>
                                <Text fontWeight="bold" color={amountColor}>
                                    {labels[orderDetails.orderStatus]}
                                </Text>
                            </Grid>

                            {(orderDetails.orderStatus === "PAYMENT_PENDING" || orderDetails.orderStatus === "PAYMENT_FAILED") && (
                                <>
                                    <Flex justifyContent="flex-start" alignItems="center" mb={1} mt={2}>
                                        <Text width="90px" mr={3} whiteSpace="nowrap">Pay:</Text>
                                        <Button colorScheme="blue" size="sm" onClick={onOpen}>Pay Now</Button>
                                    </Flex>

                                    <Flex justifyContent="flex-start" alignItems="center" mb={2} mt={2}>
                                        <Text width="90px" mr={3} whiteSpace="nowrap">If Paid:</Text>
                                        <Flex direction="column" alignItems="flex-start">
                                            <FormControl>
                                                <Input
                                                    id="screenshotUpload"
                                                    type="file"
                                                    display="none"
                                                    onChange={handleFileChange}
                                                />
                                                <Button
                                                    as="label"
                                                    // htmlFor="screenshotUpload"
                                                    cursor="pointer"
                                                    colorScheme="green"
                                                    size="sm"
                                                    isLoading={loading}
                                                    loadingText={isFileUploaded ? "Submitting" : "Uploading"}
                                                    onClick={handleButtonClick}

                                                >
                                                    {isFileUploaded ? 'Submit' : 'Upload Screenshot'}
                                                </Button>
                                            </FormControl>

                                            {fileName && (
                                                <Flex alignItems="center" mt={2}>
                                                    <Text
                                                        color={fileNameColor}
                                                        whiteSpace="nowrap"
                                                        overflow="hidden"
                                                        wordBreak="break-word"
                                                        fontSize="sm"
                                                        mr={2} // Space between file name and button
                                                    >
                                                        {fileName}
                                                    </Text>
                                                    <Button
                                                        size="xs"
                                                        colorScheme="red"
                                                        variant="solid"
                                                        borderRadius="full"
                                                        onClick={handleCancel}
                                                        width="24px"
                                                        height="24px"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        padding="0"
                                                    >
                                                        x
                                                    </Button>
                                                </Flex>
                                            )}
                                        </Flex>


                                    </Flex>
                                </>
                            )}

                            {/* Cancel Button */}
                            {(orderDetails.orderStatus === "PAYMENT_PENDING" || orderDetails.orderStatus === "PLACED" || orderDetails.orderStatus === "PAYMENT_FAILED") && (
                                <>
                                    <Flex justifyContent="flex-start" alignItems="center" mb={1}>
                                        <Text width="90px" mr={3} whiteSpace="nowrap">Cancel:</Text>
                                        <Button
                                            colorScheme="red"
                                            size="sm"
                                            onClick={() => updateOrderStatus(orderDetails.orderId, "CANCELED")}
                                            isLoading={isCanceling}
                                            loadingText="Canceling"
                                            mt={2}
                                        >
                                            Cancel
                                        </Button>
                                    </Flex>
                                </>
                            )}
                        </Box>
                    )}
                    {(orderDetails.orderStatus === "payment failed") && (
                        <Flex justifyContent="flex-start" alignItems="center" mb={1}>
                            <Text width="90px" mr={3} whiteSpace="nowrap">Reattempt Payment:</Text>
                            <Button colorScheme="orange" size="sm">Retry</Button>
                        </Flex>
                    )}

                    <Box p={5} mt="40px">
                        <Text fontSize="30px" fontWeight="bold" mb={4}>
                            Order Progress
                        </Text>

                        {/* Ensure orderDetails and orderStatus exist before passing to the tracker */}
                        {orderDetails && orderDetails.orderStatus ? (
                            <>
                                <VerticalStatusTracker
                                    statuses={
                                        orderDetails.orderStatus === "CANCELED"
                                            ? orderStatuses.filter(status => status.id !== "PAYMENT_FAILED")
                                            : orderDetails.orderStatus === "PAYMENT_FAILED"
                                                ? orderStatuses.filter(status => status.id !== "CANCELED")
                                                : orderStatuses.filter(status => status.id !== "CANCELED" && status.id !== "PAYMENT_FAILED")
                                    }
                                    currentStatus={orderDetails.orderStatus}
                                />
                                {/* Render the Download Invoice button only if isPaid is true */}
                                {orderDetails.isPaid && <Invoice orderDetails={orderDetails} />}
                            </>
                        ) : (
                            <Text>Loading order details...</Text>
                        )}
                    </Box>

                    {/* Modals */}
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent bg={modalBg} borderRadius="lg" boxShadow="xl">
                            <ModalHeader textAlign="center" fontSize="xl" color={modalTextColor}>
                                Payment Options
                            </ModalHeader>
                            <ModalCloseButton color="black" />
                            <ModalBody>
                                <Center mb={4}>
                                    <Image
                                        src={qrCodeImage}
                                        alt="QR Code for UPI Payment"
                                        width="500px"
                                        height="400px"
                                        borderRadius="md"
                                        boxShadow="md"
                                    />
                                </Center>
                                <Center>
                                    <Link
                                        href={`upi://pay?pa=${upiId}`}
                                        color={linkColor}
                                        fontWeight="bold"
                                        fontSize="lg"
                                        _hover={{
                                            textDecoration: "underline",
                                            color: linkHoverColor,
                                        }}
                                        isExternal
                                    >
                                    </Link>
                                </Center>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    colorScheme="green"
                                    variant="outline"
                                    borderColor={buttonBorderColor}
                                    color={buttonBorderColor}
                                    _hover={{
                                        borderColor: buttonHoverBorderColor,
                                        color: buttonHoverBorderColor,
                                        backgroundColor: "transparent",
                                    }}
                                    mr={3}
                                    onClick={handleDownload}
                                > Download QR</Button>
                                <Button
                                    colorScheme="green"
                                    variant="outline"
                                    borderColor={buttonBorderColor}
                                    color={buttonBorderColor}
                                    _hover={{
                                        borderColor: buttonHoverBorderColor,
                                        color: buttonHoverBorderColor,
                                        backgroundColor: "transparent",
                                    }}
                                    mr={3}
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                </>
            ) : (
                <Text>No purchases available.</Text>
            )}
        </Box>
    );
};


export default Purchase;
