import React from "react";
import starImage from '../../assets/img/star.png';

class AvatarLevel extends React.Component {
  render() {
    const avatarStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      border: "4px solid #A12323", // Example color for the circle
      width: "150px",
      height: "150px",
      margin: "0 auto", // Centering the circle
      backgroundColor: "#fff",
    };

    const imageStyle = {
      width: "80%", // Image size within the circle
      borderRadius: "50%", // To ensure the image is circular
    };

    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <div style={avatarStyle}>
          <img
            src={starImage} // Replace with actual image source
            alt="User Avatar"
            style={imageStyle}
          />
        </div>
      </div>
    );
  }
}

export default AvatarLevel;
