import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Defaultsl";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import config from '../../../config.json';

const isLocal = config.isLocal;

function SignIn() {
  const textColor = useColorModeValue("#32B44A", "#32B44A");
  const textColorSecondary = useColorModeValue("navy.700", "white");
  const textColorDetails = useColorModeValue("navy.700", "white");
  const textColorBrand = useColorModeValue("#33cc33", "#29a329");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isValidToken, setIsValidToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setIsValidToken(false);
        return;
      }

      try {
        const response = await fetch(config.API + '/auth/validateToken', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 202) {
          setIsValidToken(true);
          navigate("/user/dashboard")
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        console.error('Token validation failed:', error);
        setIsValidToken(false);
      }
    };

    validateToken();
  }, []);

  const handleLogin = async () => {
    if (isLocal) {
      navigate("/user/dashboard");
      return;
    }

    setLoading(true);
    setError(null);

    if (!userId || !password) {
      setError("UserID and Password are required.");
      setLoading(false);
      return;
    }
    try {
      const response = await fetch(config.API + "/auth/login", {
        method: 'POST',
        body: JSON.stringify({
          userId: userId,
          pwd: password
        })
      });
      if (response.status === 202) {
        const data = await response.json();

        const userData = {
          ...data["user"], // spread the existing user data from API response
          level: data["user"]?.level || "default", // Ensure level property is included
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("userData", data["user"]);
        localStorage.setItem("authToken", data["auth-token"]);
        localStorage.setItem("userId", userId);
        window.location.href = "/user/dashboard";
      } else if (response.status === 401) {
        setError("UserId or password is incorrect. Please try again.");
      } else {
        setError(response.data.message || "Login failed. Please try again.");
      }
    } catch (error) {
      setError("An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "100%" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "20px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Login
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your username and password to Login !
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>

          <FormControl>
            <FormLabel>UserID</FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              type="text"
              placeholder="UserID123"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />

            <FormLabel>Password</FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            {error && (
              <Text color="red.500" mb="24px">
                {error}
              </Text>
            )}

            <Button
              backgroundColor="#32B44A"
              fontSize="sm"
              variant="solid"
              fontWeight="500"
              w="100%"
              h="50px"
              mb="24px"
              _hover={{ backgroundColor: "#33cc33" }}
              _active={{ backgroundColor: "#29a329" }}
              _focus={{ boxShadow: "none" }}
              onClick={handleLogin}
              isLoading={loading}
            >
              Login
            </Button>
          </FormControl>

          <Flex justifyContent="space-between" alignItems="center" mb="24px">
            <Box />
            <NavLink to="/user/resetPassword">
              <Text
                color={textColorBrand}
                fontWeight="500"
                fontSize="sm"
                _hover={{ textDecoration: "underline" }}
                textAlign="right"
              >
                Forgot Password?
              </Text>
            </NavLink>
          </Flex>

          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'
            mb='-40px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/user/register'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Register Here
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
