import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue,
    useToast, 
} from "@chakra-ui/react";
import illustration from "assets/img/auth/auth.png";
import DefaultAuth from "layouts/auth/Defaultsl";
import { useState } from "react";
import config from "../../../config.json";
const isLocal = config.isLocal;

function Reset() {
    const toast = useToast(); 
    const textColor = useColorModeValue("#32B44A", "#32B44A");
    const textColorSecondary = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "white");
    const textColorBrand = useColorModeValue("#33cc33", "#29a329");
    const brandStars = useColorModeValue("brand.500", "brand.400");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false); 
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [resetToken, setResetToken] = useState(""); 

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-]).{8,}$/;
        return passwordRegex.test(password);
    };

    // Function to handle sending OTP
    const handleGetOtp = async () => {
        if (!phoneNumber) {
            setError("Phone number is required.");
            return;
        }
        setError(null);
        setIsLoading(true);
        try {
            const response = await fetch(`${config.API}/auth/resetPassword/token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ phoneNumber }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || "Failed to send OTP.");
            }

            setIsOtpSent(true);
            toast({
                title: "OTP Sent",
                description: "OTP sent successfully! Please check your phone.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error during OTP request:", error);
            setError(error.message || "An unexpected error occurred.");
            toast({
                title: "Error",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyOtp = async () => {
        if (!otp) {
            setError("Please enter OTP.");
            return;
        }
        setError(null);
        setIsLoading(true);
        try {
            const response = await fetch(`${config.API}/auth/resetPassword/verifyToken`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ phoneNumber, otp }),
            });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || "OTP verification failed.");
            }
            const result = await response.json();
            console.log("OTP verified successfully:", result);

            const { resetToken } = result;
            setResetToken(resetToken);
            setIsOtpVerified(true);
            toast({
                title: "OTP Verified",
                description: "OTP verified successfully! Please set your new password.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error("Error during OTP verification:", error);
            setError(error.message || "An unexpected error occurred.");
            toast({
                title: "Error",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFinalResetPassword = async () => {
        if (!phoneNumber) {
            setError("Phone number is required.");
            return;
        }
        if (!resetToken) {
            setError("Reset token is missing. Please verify OTP first.");
            return;
        }
        if (!newPassword) {
            setError("New password is required.");
            return;
        }
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        if (!validatePassword(newPassword)) {
            setError("Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.");
            return;
        }
        setError(null);
        setIsLoading(true);
        try {
            const response = await fetch(`${config.API}/auth/resetPassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    phoneNumber,
                    resetToken,
                    newPassword
                }),
            });

            const responseText = await response.text();  
            console.log("Raw Response:", responseText);   

            if (!response.ok) {
                setError(responseText || "Password reset failed.");
                toast({
                    title: "Error",
                    description: responseText || "Password reset failed.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            if (responseText.includes("Client user password reset successfully!")) {
                console.log("Password reset successful:", responseText);
                toast({
                    title: "Success",
                    description: "Password reset successful! You can now sign in with your new password.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });

                window.location.href = "/user/login"; 
            } else {
                setError("Unexpected response from server.");
                toast({
                    title: "Error",
                    description: "Unexpected response from server.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Error during password reset:", error);
            setError(error.message || "An unexpected error occurred.");
            toast({
                title: "Error",
                description: error.message || "An unexpected error occurred.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "100%" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "20px", md: "14vh" }}
                flexDirection="column"
            >
                <Box>
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        Reset Your Password
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
                        Enter your details to reset your password.
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mb={{ base: "20px", md: "auto" }}
                    maxH="100vh"
                    justifyContent="space-between"
                >
                    <FormControl>
                        {!isOtpSent ? (
                            <>
                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    mb="8px"
                                >
                                    Phone Number<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Input
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: "0px", md: "0px" }}
                                    type="text"
                                    mb="24px"
                                    fontWeight="500"
                                    size="lg"
                                    placeholder="Enter your phone number"
                                />
                                <Flex justifyContent="center" w="100%">
                                    <Button
                                        backgroundColor="#32B44A"
                                        fontSize="sm"
                                        fontWeight="500"
                                        w="50%"
                                        h="50px"
                                        mb="24px"
                                        mx="auto"
                                        alignSelf="center"
                                        isLoading={isLoading}
                                        isDisabled={phoneNumber.trim() === ""}
                                        _hover={{
                                            backgroundColor: "#33cc33",
                                        }}
                                        _active={{
                                            backgroundColor: "#29a329",
                                        }}
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                        onClick={handleGetOtp}
                                    >
                                        Get OTP
                                    </Button>
                                </Flex>
                            </>
                        ) : !isOtpVerified ? (
                            <>
                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    mb="8px"
                                >
                                    Enter OTP<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Input
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: "0px", md: "0px" }}
                                    type="text"
                                    mb="24px"
                                    fontWeight="500"
                                    size="lg"
                                    placeholder="Enter the OTP"
                                />
                                <Flex justifyContent="center" w="100%">
                                    <Button
                                        backgroundColor="#32B44A"
                                        fontSize="sm"
                                        fontWeight="500"
                                        w="50%"
                                        h="50px"
                                        mb="24px"
                                        alignSelf="center"
                                        isLoading={isLoading}
                                        isDisabled={otp.trim() === ""}
                                        _hover={{
                                            backgroundColor: "#33cc33",
                                        }}
                                        _active={{
                                            backgroundColor: "#29a329",
                                        }}
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                        onClick={handleVerifyOtp}
                                    >
                                        Verify OTP
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <>
                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    mb="8px"
                                >
                                    New Password<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Input
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: "0px", md: "0px" }}
                                    type="password"
                                    mb="24px"
                                    fontWeight="500"
                                    size="lg"
                                    placeholder="Enter your new password"
                                />

                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    mb="8px"
                                >
                                    Confirm New Password<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Input
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: "0px", md: "0px" }}
                                    type="password"
                                    mb="24px"
                                    fontWeight="500"
                                    size="lg"
                                    placeholder="Confirm your new password"
                                />
                                <Flex justifyContent="center" w="100%">
                                    <Button
                                        backgroundColor="#32B44A"
                                        fontSize="sm"
                                        fontWeight="500"
                                        w="50%"
                                        h="50px"
                                        mb="24px"
                                        alignSelf="center"
                                        isLoading={isLoading}
                                        _hover={{
                                            backgroundColor: "#33cc33",
                                        }}
                                        _active={{
                                            backgroundColor: "#29a329",
                                        }}
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                        onClick={handleFinalResetPassword}
                                    >
                                        Reset Password
                                    </Button>
                                </Flex>
                            </>
                        )}

                        {error && (
                            <Text color="red.500" fontSize="sm" mt="2">
                                {error}
                            </Text>
                        )}
                    </FormControl>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default Reset;
