import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from './layouts/admin';
import UserLayout from 'layouts/user';
import PaymentProcess from 'components/PaymentProcess/PaymentProcess';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import Orders from 'User-dashboard/Dashboard/Orders';
import Purchase from 'User-dashboard/Dashboard/Order'
import {
  ChakraProvider,
} from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useState } from 'react';
import { AppProvider } from 'AppContext';
import { CartProvider } from 'components/CartContext/CartContext';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (
    <AppProvider>
      <CartProvider> {/* Wrap with CartProvider */}
        <ChakraProvider theme={currentTheme}>
          <Routes>
            {/* Define your routes */}
            {/* <Route path="auth/*" element={<AuthLayout />} /> */}
            <Route path="admin/*" element={<AdminLayout />} />
            {/* <Route path="rtl/*" element={<RTLLayout />} /> */}
            <Route
              path="user/*"
              element={<UserLayout theme={currentTheme} setTheme={setCurrentTheme} />}
            >
              {/* PaymentProcess and OrderSummary routes under 'user' */}
              <Route path="payment-process" element={<PaymentProcess />} />
              <Route path="order-summary" element={<OrderSummary />} />
              {/* Add a route for Orders */}
              <Route path="orders" element={<Orders />} />
              <Route path="order/get/:orderId" element={<Purchase />} />

            </Route>
            
            {/* Default and Catch-All Routes */}
            <Route path="/" element={<Navigate to="/user/login" replace />} />
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </ChakraProvider>
      </CartProvider>
    </AppProvider>
  );
}
