import React, { Component } from 'react'
import SignIn from 'views/auth/signIn'

export class Login extends Component {
  render() {
    return (
       <SignIn />
    )
  }
}

export default Login
