import { WarningIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { removeItemsFromLocalStorage } from "components/utils/localStorageUtils"; // Import the utility function

export default function LogoutComponent({ onLogout }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isLoggedOutModalOpen, onOpen: onLoggedOutModalOpen, onClose: onLoggedOutModalClose } = useDisclosure();

  const handleLogout = () => {
    // Call the utility function to remove multiple items from localStorage
    removeItemsFromLocalStorage(["authToken", "userData", "cartItems", "userId"]); // Pass the items you want to remove
    onLogout();
    window.location.href = "/user/login";
    onClose();
    onLoggedOutModalOpen();
  };

  return (
    <Flex direction="column" align="center" justify="center" height="5vh">
      {/* Button to trigger logout confirmation */}
      <Button onClick={onOpen} colorScheme="green">
        Log out
      </Button>

      {/* Logout Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you leaving?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" align="center" justify="center">
              <Icon as={WarningIcon} color="green.500" w={10} h={10} />
              <Text mt={4}>Are you sure you want to log out?</Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleLogout} ml={3}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
