import React, { createContext, useContext, useState, useEffect } from 'react';
import config from '../../config.json';

const CartContext = createContext();
const isLocal = config.isLocal;

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedCart = localStorage.getItem('cartItems');
    return storedCart ? JSON.parse(storedCart) : [];
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const getCart = async () => {
    if (!isLocal) {
      const isCartCleared = localStorage.getItem("cartCleared") === "true";
      if (isCartCleared) {
        setCartItems([]); // Ensure the cart stays empty
        return;
      }
  
      setLoading(true);
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(config.API + "/cart/items", {
          method: "GET",
          headers: { "Authorization": `Bearer ${token}` },
        });
        if (response.status === 200) {
          const data = await response.json();
          setCartItems(data.cartItems);
        } else if (response.status === 401) {
          localStorage.removeItem("authToken");
          window.location.href = "/user/login";
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
      setLoading(false);
    }
  };
  

  const addToCart = async (product, quantity) => {
    setLoading(true);
    // Reset the cartCleared flag since the user is adding items again
  localStorage.setItem("cartCleared", "false");
    if (isLocal) {
      setCartItems((prevItems) => {
        const existingItem = prevItems.find(item => item.productCode === product.productCode);
        if (existingItem) {
          return prevItems.map(item =>
            item.productCode === product.productCode
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        } else {
          return [...prevItems, { ...product, quantity: 1 }];
        }
      });
    } else {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(config.API + "/cart/add", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            productCode: product.productCode,
            quantity: quantity,
          }),
        });
        if (response.ok) {
          const addedProduct = await response.json();
          setCartItems((prevItems) => {
            const existingItem = prevItems.find(item => item.productCode === addedProduct.productCode);
            if (existingItem) {
              return prevItems.map(item =>
                item.productCode === addedProduct.productCode
                  ? { ...item, quantity: item.quantity + 1 }
                  : item
              );
            } else {
              return [...prevItems, { ...addedProduct, quantity: addedProduct.quantity }];
            }
          });
        }
        else if (response.status === 401) {
          localStorage.removeItem("authToken");
          window.location.href = "/user/login";
        }
        else {
          console.error("Failed to add to cart");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setLoading(false);
  };

  const adjustCartQuantity = async (productCode, action) => {
    setLoading(true);
    if (isLocal) {
      setCartItems((prevItems) => {
        return prevItems.map(item => {
          if (item.productCode === productCode) {
            if (action === 'increase') {
              return { ...item, quantity: item.quantity + 1 };
            } else if (action === 'decrease' && item.quantity > 1) {
              return { ...item, quantity: item.quantity - 1 };
            }
          }
          return item;
        });
      });
    } else {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(`${config.API}/cart/adjust?productCode=${productCode}&action=${action}`, {
          method: 'PATCH',
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
          setCartItems((prevItems) => {
            return prevItems.map(item => {
              if (item.productCode === productCode) {
                if (action === 'increase') {
                  return { ...item, quantity: item.quantity + 1 };
                } else if (action === 'decrease' && item.quantity > 1) {
                  return { ...item, quantity: item.quantity - 1 };
                }
              }
              return item;
            });
          });
        }
        else if (response.status === 401) {
          localStorage.removeItem("authToken");
          window.location.href = "/user/login";
        }
        else {
          console.error("API returned an error.");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
    setLoading(false);
  };

  const removeFromCart = async (productCode) => {
    setLoading(true);
    if (isLocal) {
      setCartItems((prevItems) => prevItems.filter((item) => item.productCode !== productCode));
    } else {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(`${config.API}/cart/remove?productCode=${productCode}`, {
          method: 'DELETE',
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.ok) {
          setCartItems((prevItems) => prevItems.filter((item) => item.productCode !== productCode));
        }
        else if (response.status === 401) {
          localStorage.removeItem("authToken");
          window.location.href = "/user/login";
        }
        else {
          console.error("Failed to remove item from cart.");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
    setLoading(false);
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, adjustCartQuantity, setCartItems, getCart, loading }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
