import React from "react";
import { Flex, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";

// Import logos
const logo = require("assets/img/grcs/logo-grcs.png");
const logowhite = require("assets/img/grcs/inverselogo.png");

export function SidebarBrand() {
  // Use Chakra color mode hook
  const { colorMode } = useColorMode();

  // Determine logo based on color mode
  const logoSrc = useColorModeValue(logo, logowhite);

  return (
    <Flex align='center' direction='column'>
      <img src={logoSrc} alt='Logo' style={{ width: "195px", height: "75px", marginBottom: "9px"}}/>
      <HSeparator mb='px' />
    </Flex>
  );
}

export default SidebarBrand;
