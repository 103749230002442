// localStorageUtils.js

// Function to clear specific items from localStorage
export const removeItemsFromLocalStorage = (items) => {
    items.forEach((item) => {
      localStorage.removeItem(item);
    });
  };
  
  // Function to clear all data from localStorage
  export const clearLocalStorage = () => {
    localStorage.clear();
  };
  