import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState } from "react";

export default function ProductCard(props) {
  const { image, name, category, price, productUrl, addToCart, discountPrice, viewProduct } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorPrice = useColorModeValue("#32B44A", "white");

  return (
    <Card p='20px'>
      <Flex direction={{ base: "column" }} justify='center'>
        {/* Image section */}
        <Box mb={{ base: "20px", "2xl": "20px" }} position='relative'>
          <Image
            src={image}
            width="100%" 
            height={{ base: "300px", md: "250px" }}
            objectFit="cover" 
            borderRadius='20px'
          />
        </Box>

        {/* Product details */}
        <Flex flexDirection='column' justify='space-between' h='100%'>
          <Text
            color={textColor}
            fontSize={{ base: "xl", md: "lg", lg: "lg", xl: "lg", "2xl": "md", "3xl": "lg" }}
            mb='5px'
            fontWeight='bold'
            isTruncated>
            {name}
          </Text>
          <Text
            color='secondaryGray.600'
            fontSize={{ base: "sm" }}
            fontWeight='400'
            isTruncated>
            {category}
          </Text>
          <Text fontWeight='700' fontSize='lg' color={textColorPrice} mt='10px' isTruncated>
            MRP: {price}
          </Text>
          <Text fontWeight='700' fontSize='lg' color={textColorPrice} mt='10px' isTruncated>
            DiscountPrice: {discountPrice}
          </Text>

          {/* Add to Cart button */}
          <Link href={productUrl} mt='15px'>
            <Button
              variant='solid'
              bg='#33cc33'
              _hover="#32B44A"
              _active="#32B44A"
              textColor="black"
              textAlign="center"
              fontSize='md'
              fontWeight='500'
              borderRadius='8px'
              px='24px'
              py='10px'
              onClick={viewProduct}>
              View Product
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Card>
  );
}
