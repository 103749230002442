import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaCashRegister, FaMobileAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config.json";
import { useEffect } from "react";
import { useCart } from "components/CartContext/CartContext";

const isLocal = config.isLocal;

const PaymentProcess = () => {
  const location = useLocation();
  const product = location.state?.product || null;
  const cartItems = location.state?.cartItems || null;
  const quantity = location.state?.quantity || 1;
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const [userLevel, setUserLevel] = useState(null);
  const { onOpen } = useDisclosure();
  const navigate = useNavigate();
  const { setCartItems } = useCart();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.userId) {
      setUserLevel(userData.level);
    }
  }, []);

  const calculateTotalForProduct = () => {
    if (!product) return 0;
    if (userLevel === "Unknown") {
      return product.mrp * quantity;
    } else {
      return product.discountPrice * quantity;
    }
  };

  const calculateTotalForCart = () => {
    if (!cartItems.length) return 0;
    return cartItems.reduce((total, item) => {
      const price = userLevel === "Unknown" ? item.mrp : item.discountPrice;
      return total + price * item.quantity;
    }, 0);
  };

  const handleCheckout = async () => {
    const token = localStorage.getItem("authToken");
    const { product, quantity, fromBuyNow } = location.state || {};
    const buyNowItems = fromBuyNow ? [{ productCode: product.productCode, quantity }] : [];
    const orderItems = [
      ...(Array.isArray(cartItems)
        ? cartItems.map((item) => ({
            productCode: item.productCode,
            quantity: item.quantity,
          }))
        : []),
      ...buyNowItems,
    ];
  
    if (orderItems.length === 0) return;
  
    try {
      const response = await fetch(`${config.API}/order/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ orderItems, paymentType: paymentMethod }),
      });
  
      if (response.status === 401) {
        localStorage.removeItem("authToken");
        window.location.href = "/user/login";
        return;
      }
  
      if (response.ok) {
        const responseData = await response.text();
        const orderIdMatch = responseData.match(/ID:\s*(\d+)/);
  
        if (orderIdMatch) {
          const orderId = orderIdMatch[1];
        
          if (!fromBuyNow) {
            // Clear the cart in the local state
            setCartItems([]); // Clear the cart locally
            localStorage.setItem("cartCleared", "true"); // Set a flag in localStorage
          }
        
          navigate(`/user/order/get/${orderId}`, {
            state: {
              cartItems: fromBuyNow ? [product] : [],
              isFromCart: !fromBuyNow,
            },
          });
        }
        
      } else {
        console.error("Failed to create the order:", await response.text());
      }
    } catch (error) {
      console.error("Checkout error:", error);
    }
  };
  
  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
    if (value === "UPI") {
      onOpen();
    }
  };

  return (
    <Box
      padding={{ base: "500px 2px 5px", md: "40px" }}
      paddingBottom={{ base: "400px", md: "10px" }}
      maxWidth="1200px"
      margin="0 auto"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      color="inherit"
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={4}
        padding="20px"
        borderRadius="lg"
        boxShadow="xl"
      >
        <GridItem>
          {product ? (
            <>
              <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold" mb={2}>
                Selected Product: {product.productName}
              </Text>
              <Image
                src={product.productImage}
                alt={product.productName}
                boxSize={{ base: "120px", md: "150px", lg: "180px" }}
                objectFit="cover"
                mb={4}
                borderRadius="md"
                boxShadow="lg"
              />
              <Text fontSize={{ base: "md", md: "lg" }}>Quantity: {quantity}</Text>
              <Text fontWeight="bold" fontSize={{ base: "lg", md: "xl" }}>
                Total:  <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>{calculateTotalForProduct()}.00
              </Text>
            </>
          ) : (
            <>
              <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold" mb={2}>
                Selected Products:
              </Text>
              <Box
                height={{ base: "200px", md: "350px" }}
                overflowY="auto"
                border="2px solid"
                borderColor="gray.300"
                borderRadius="lg"
                padding="15px"
                boxShadow="lg"
                marginBottom="20px"
              >
                {cartItems.map((item, index) => (
                  <Box
                    key={index}
                    marginBottom="12px"
                    padding="15px"
                    border="2px solid"
                    borderColor="gray.200"
                    borderRadius="md"
                    boxShadow="md"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex alignItems="center">
                      <Image
                        src={item.productImage}
                        alt={item.productName}
                        boxSize={{ base: "50px", md: "70px", lg: "90px" }}
                        objectFit="cover"
                        mr={4}
                        borderRadius="md"
                        boxShadow="md"
                      />
                      <Box>
                        <Text fontSize={{ base: "sm", md: "md" }} fontWeight="semibold">
                          {item.productName}
                        </Text>
                        <Text fontSize="xs">Quantity: {item.quantity}</Text>
                        <Text fontSize={{ base: "sm", md: "md" }} fontWeight="bold" mt={2}>
                          <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>
                          {item.discountPrice * item.quantity}.00
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </Box>
              <Divider mb={4} />
              <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold">
                Total: <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>{calculateTotalForCart()}.00
              </Text>
            </>
          )}
        </GridItem>

        <GridItem>
          <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold" mb={4}>
            Choose Payment Method
          </Text>
          <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
            <Stack spacing={4}>
              <Box
                border="2px solid"
                borderRadius="lg"
                p={4}
                borderColor="gray.200"
                boxShadow="lg"
                display="flex"
                alignItems="center"
              >
                <Radio value="UPI" mr={3}>
                  UPI
                </Radio>
                <FaMobileAlt size="24px" color="green" />
              </Box>

              <Box
                border="2px solid"
                borderRadius="lg"
                p={4}
                borderColor="gray.200"
                boxShadow="lg"
                display="flex"
                alignItems="center"
              >
                <Radio value="COD" mr={3}>
                  Cash on Delivery
                </Radio>
                <FaCashRegister size="24px" color="gray" />
              </Box>
            </Stack>
          </RadioGroup>

          <Button
            onClick={handleCheckout}
            colorScheme="green"
            mt={8}
            size="lg"
            fontSize={{ base: "lg", md: "xl" }}
            width="100%"
            boxShadow="xl"
            _hover={{ transform: "scale(1.05)", transition: "transform 0.2s ease-in-out" }}
          >
            Place Order
          </Button>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default PaymentProcess;
