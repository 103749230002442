import { Box, Button, FormControl, FormLabel, Input, Text, useBreakpointValue, useColorModeValue, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import config from "../../config.json"; 
const isLocal = config.isLocal; 

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); 
  const [success, setSuccess] = useState(''); 

  const bgColor = '#32B44A';
  const inputBorderColor = useColorModeValue('lightgray', 'gray.600');
  const inputHoverColor = useColorModeValue('gray.300', 'gray.500');
  const inputTextColor = useColorModeValue('black', 'white');
  const wordColor = useColorModeValue('black', 'white');
  const bgBoxColor = useColorModeValue('white', 'navy.800');
  const formControlWidth = useBreakpointValue({ base: '90%', md: '500px' });


  const validatePassword = (password) => {
    const rules = [
      /.{8,}/, // At least 8 characters
      /[A-Z]/, // At least one uppercase letter
      /[a-z]/, // At least one lowercase letter
      /[0-9]/, // At least one numeral
      /[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-]/ 
    ];
    return rules.every((rule) => rule.test(password));
  };

  useEffect(() => {
    const isValid = validatePassword(newPassword);
    console.log('Is password valid:', isValid);  // Log the validity status
    setIsPasswordValid(isValid);
    setPasswordsMatch(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);
  

  const handlePasswordChange = async () => {
    setHasAttemptedSubmit(true);
    setError('');
    setSuccess('');
  
    if (isPasswordValid && passwordsMatch) {
      setLoading(true);
  
      const token = localStorage.getItem('authToken');
      const body = {
        currentPassword,
        newPassword,
      };
  
      try {
        let response;
        // Make API call for password change
        if (!config.isLocal) {
          response = await fetch(config.API + '/user/update/password', {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
          });
        } else {
          response = { ok: true }; // Mocked response when local
        }
  
        // Check if the response is successful
        if (response.ok) {
          const responseBody = await response.text(); // Get the response as plain text
  
          // Handle success response
          setSuccess(responseBody || 'Password successfully changed!');
          
          // Show a toast notification upon success
          localStorage.setItem('passwordChangeSuccess', 'Your password has been successfully changed.');
  
          // Refresh the page
          window.location.reload(); // Reload the page to apply changes
        } else {
          const errorData = await response.text(); // Handle error response as plain text
          setError(errorData || 'Failed to change password.');
        }
      } catch (err) {
        // Handle network or unexpected errors
        setError('An error occurred while changing password.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      // If password does not meet validation or passwords don't match
      if (!isPasswordValid) {
        setError('New password does not meet the requirements. Ensure it includes at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.');
      }
      if (!passwordsMatch) {
        setError('Passwords do not match.');
      }
    }
  };
  
  
  return (
    <Box mt="40px" p={{ base: '20px', md: '40px' }} bg={bgBoxColor} maxW={{ base: '100%', md: '650px' }} borderRadius="lg" boxShadow="md">
      <FormControl mb="15px" width={formControlWidth} isRequired>
        <FormLabel>Current Password</FormLabel>
        <Input
          placeholder="Enter current password"
          color={inputTextColor}
          borderColor={inputBorderColor}
          _hover={{ borderColor: inputHoverColor }}
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          _focus={{ borderColor: bgColor, boxShadow: `0 0 0 1px ${bgColor}` }}
        />
      </FormControl>

      <FormControl mb="15px" width={formControlWidth} isRequired>
        <FormLabel>New Password</FormLabel>
        <Input
          placeholder="Enter new password"
          color={inputTextColor}
          borderColor={inputBorderColor}
          _hover={{ borderColor: inputHoverColor }}
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          _focus={{ borderColor: bgColor, boxShadow: `0 0 0 1px ${bgColor}` }}
        />
      </FormControl>

      {hasAttemptedSubmit && !isPasswordValid && (
        <Text color="red.500" fontSize="sm" mt="2">
          Password does not meet the requirements.
        </Text>
      )}

      <FormControl mb="15px" width={formControlWidth} isRequired>
        <FormLabel>Confirm New Password</FormLabel>
        <Input
          placeholder="Confirm new password"
          color={inputTextColor}
          borderColor={inputBorderColor}
          _hover={{ borderColor: inputHoverColor }}
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          _focus={{ borderColor: bgColor, boxShadow: `0 0 0 1px ${bgColor}` }}
        />
      </FormControl>

      {hasAttemptedSubmit && !passwordsMatch && (
        <Text color="red.500" fontSize="sm" mt="2">
          Passwords do not match.
        </Text>
      )}

      {error && <Text color="red.500" fontSize="sm" mt="2">{error}</Text>}
      {success && <Text color="green.500" fontSize="sm" mt="2">{success}</Text>}

      <Box display="flex" justifyContent="flex-start">
        <Button
          bg={bgColor}
          color="white"
          onClick={handlePasswordChange}
          _hover={{ bg: '#32B44A' }}
          _focus={{ bg: '#32B44A' }}
          _active={{ bg: '#32B44A' }}
          isLoading={loading} 
        >
          Change Password
        </Button>
      </Box>
    </Box>
  );
};

export default function Settings() {
  const [activeScreen, setActiveScreen] = useState('changePassword');
  const bgColor = '#32B44A';
  const borderColor = '#32B44A';
  const textColor = useColorModeValue('black', 'white');
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const buttonPadding = useBreakpointValue({ base: '8px', md: '12px' });
  const buttonFontSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const toast = useToast();  // Initialize the toast hook

  useEffect(() => {
    const successMessage = localStorage.getItem('passwordChangeSuccess');
    if (successMessage) {
      toast({
        title: 'Password Changed',
        description: successMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Clear the success message after showing the toast
      localStorage.removeItem('passwordChangeSuccess');
    }
  }, [toast]);

  return (
    <Box>
      <Box display="flex" flexDirection={{ base: 'row', md: 'row' }} justifyContent="flex-start" mb="5px" mt="90px" gap="10px" flexWrap="wrap">
        <Button
          onClick={() => setActiveScreen('changePassword')}
          size={buttonSize}
          p={buttonPadding}
          fontSize={buttonFontSize}
          bg={activeScreen === 'changePassword' ? bgColor : 'transparent'}
          color={activeScreen === 'changePassword' ? 'white' : textColor}
          border={activeScreen === 'changePassword' ? 'none' : `1px solid ${borderColor}`}
          _hover={{ bg: activeScreen === 'changePassword' ? '#32B44A' : 'transparent' }}
          _focus={{ bg: activeScreen === 'changePassword' ? '#32B44A' : 'transparent' }}
          _active={{ bg: activeScreen === 'changePassword' ? '#32B44A' : 'transparent' }}
        >
          Change Password
        </Button>
      </Box>

      <Box>
        {activeScreen === 'changePassword' && <ChangePassword />}
      </Box>
    </Box>
  );
}
