// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React from "react";

export default function Conversion(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card
      p={{ base: '10px', md: '20px' }} // Responsive padding
      align="center"
      direction="column"
      w="100%"
      h="100%"
      {...rest}
    >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
        flexDirection={{ base: "column", md: "row" }} // Stack content on mobile
      >
        <Text
          color={textColor}
          fontSize={{ base: "sm", md: "md" }} // Responsive font size
          fontWeight="600"
          mt="4px"
          textAlign={{ base: "center", md: "start" }} // Center text on mobile
        >
          Your Level
        </Text>
      </Flex>

      {/* Pie Chart */}
      <Box w="100%" h="100%" mt={{ base: "10px", md: "0px" }}>
        <PieChart
          h={{ base: '250px', md: '100%' }} // Set a fixed height on mobile
          w="100%"
          chartData={pieChartData}
          chartOptions={pieChartOptions}
        />
      </Box>
    </Card>
  );
}
