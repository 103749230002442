import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box, Button, FormControl, FormLabel,
  Input,
  Menu,
  MenuButton, MenuItem, MenuList,
  Stack,
  Textarea,
  useBreakpointValue,
  useColorModeValue,
  VStack,
  Center,
  Text,
  Flex
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import config from "../../config.json";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import labels from "components/label/lables";
import { useToast } from '@chakra-ui/react';
const isLocal = config.isLocal;


const FormPage = () => {
  const [activeSection, setActiveSection] = useState('identity');
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isKYCUnderReview, setIsKYCUnderReview] = useState(false);
  const [kycDetails, setKycDetails] = useState({
    userId: "",
    nomineeName: "",
    nomineeRelationship: "",
    nomineeDob: "",
    country: "India",
    state: "Tamil Nadu",
    district: "",
    pinCode: "",
    address: "",
    aadhaarNumber: "",
    pan: "",
    bankName: "",
    bankAccountNumber: "",
    bankIfscCode: "",
    bankBranch: "",
    bankHolderName: "",
    aadhaarPhoto: "",
    panPhoto: "",
    bankBookOrChequePhoto: "",
  });
  const [accountNumber, setAccountNumber] = useState("");
  const [retypeAccountNumber, setRetypeAccountNumber] = useState('');
  const [isAccountNumberChanged, setIsAccountNumberChanged] = useState(false);


  const [KycFiles, setKycFiles] = useState({
    aadhaarPhoto: null,
    panPhoto: null,
    bankBookOrChequePhoto: null,
    aadhaarPhotoChanged: false,
    panPhotoChanged: false,
    bankBookOrChequePhotoChanged: false
  })


  const userData = JSON.parse(localStorage.getItem("userData"));


  useEffect(() => {
    const getKycDetails = async () => {
      const token = localStorage.getItem("authToken");
      const response = await fetch(`${config.API}/user/kyc/get?userId=${userData.userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/user/login");
      }
      if (response.ok) {
        const data = await response.json();
        setKycDetails(data);
      } else {
        console.error('Error fetching KYC details:', response);
      }
    }
    if (isLocal) {
      fetch('/data/districts.json')
        .then(response => response.json())
        .then(data => {
          const tamilNaduDistricts = data.find(d => d.state === "Tamil Nadu").districts;
          setDistrictsData(tamilNaduDistricts);
          setFilteredDistricts(tamilNaduDistricts);
        })
        .catch(error => console.error("Error loading districts:", error));
    } else {
      getKycDetails();
    }
  }, []);

  const fetchDistricts = async () => {
    try {
      const response = await fetch('/data/districts.json');
      const data = await response.json();
      const tamilNaduDistricts = data.find(d => d.state === "Tamil Nadu")?.districts || [];

      setDistrictsData(tamilNaduDistricts);
      setFilteredDistricts(tamilNaduDistricts);

    } catch (error) {
      console.error("Error loading districts:", error);
    }
  };
  useEffect(() => {
    fetchDistricts();
  }, []);


  const verifyKYC = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      console.log(userId)
      const token = localStorage.getItem("authToken");

      if (!userId || !token) {
        throw new Error('User ID or auth token is missing');
      }
      const response = await fetch(`${config.API}/user/kyc/updateStatus?userId=${userId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status: "VERIFICATION_REQUESTED" })
      });
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("authToken");
          window.location.href = "/user/login";
          return;
        }
        throw new Error('Failed to update KYC status');
      }
      const data = await response.text();
      setIsKYCUnderReview(true);
    } catch (error) {
      console.error('Error updating KYC status:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleSelect = (value) => {
    setKycDetails((prevData) => ({
      ...prevData,
      nomineeRelationship: value,
    }));
  };

  const handleSelectDistrict = (district) => {
    setKycDetails((prevData) => ({
      ...prevData,
      district: district,
    }));
  };

  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const formBg = useColorModeValue('white');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const inputBorderColor = useColorModeValue('green.500', 'green.300');
  const hoverBorderColor = useColorModeValue('green.600', 'green.400');
  const labelColor = useColorModeValue('gray.700', 'gray.300');
  const buttonWidth = useBreakpointValue({ base: '50%', md: '15%' });
  const stackDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const fieldBg = useColorModeValue('white', 'navy.800')
  const menuBg = useColorModeValue('white', 'navy.800')
  const fieldColor = useColorModeValue('#D3D3D3', '#424242')

  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleInputChange = (e) => {
    const { id, value } = e.target;

    let formattedValue = value;

    // Field-specific validation inside handleInputChange
    switch (id) {
      case "aadhaarNumber":
        formattedValue = value.replace(/\D/g, '');
        if (formattedValue.length > 12) formattedValue = formattedValue.substring(0, 12);
        formattedValue = formattedValue.match(/.{1,4}/g)?.join(' ') || formattedValue;
        break;

      case "pan":
        formattedValue = value.toUpperCase(); 
        const panRegex = /^[A-Z]{0,5}[0-9]{0,4}[A-Z]{0,1}$/;
        if (panRegex.test(formattedValue)) {
          setKycDetails((prevData) => ({ ...prevData, pan: formattedValue }));
        }
        return;

      case "bankName":
      case "bankHolderName":
      case "branchName":
        formattedValue = value.replace(/[^a-zA-Z\s]/g, '');
        break;

      case "bankIfscCode":
        formattedValue = value.toUpperCase(); 
        const ifscRegex = /^[A-Z]{0,4}0[A-Z0-9]{0,6}$/;
        if (formattedValue.length > 11) {
          formattedValue = formattedValue.slice(0, 11); 
        }

        if (ifscRegex.test(formattedValue)) {
          setKycDetails((prevData) => ({ ...prevData, bankIfscCode: formattedValue }));
        }
        break; 

      case "pinCode":
        formattedValue = value.replace(/\D/g, ''); 
        if (formattedValue.length > 6) {
          formattedValue = formattedValue.slice(0, 6); 
        }
        break;



      default:
        break;
    }

    // Update the state with the formatted value for other fields
    setKycDetails((prevData) => ({ ...prevData, [id]: formattedValue }));
  };


  const handleFileChange = (e) => {
    const { id, files } = e.target;
    const file = files[0];

    if (file) {
      // Update KycFiles
      setKycFiles((prevData) => ({
        ...prevData,
        [id]: file,
        [`${id}Changed`]: true,
      }));

      setKycDetails((prevData) => ({
        ...prevData,
        [id]: file.name,
      }));
    }
  };

  const handleBack = () => {
    if (activeSection === "fileUpload") {
      setActiveSection("address");
    } else if (activeSection === "address") {
      setActiveSection("identity");
    }
  };

  const toast = useToast();

  const handleSubmit = async () => {
    let missingFields = [];

    // Validate required fields in kycDetails
    if (!kycDetails.nomineeName) missingFields.push("Nominee/Deputy Member Name");
    if (!kycDetails.nomineeRelationship) missingFields.push("Nominee/Deputy Member Relationship");
    if (!kycDetails.nomineeDob) missingFields.push("Nominee/Deputy Member Date of Birth");
    if (!kycDetails.country) missingFields.push("Country");
    if (!kycDetails.state) missingFields.push("State");
    if (!kycDetails.district) missingFields.push("District");
    if (!kycDetails.address) missingFields.push("Address");
    if (!kycDetails.pinCode) missingFields.push("Pin Code");
    if (!kycDetails.aadhaarNumber) missingFields.push("Aadhaar Number");
    if (!kycDetails.pan) missingFields.push("PAN");
    if (!kycDetails.bankName) missingFields.push("Bank Name");
    if (!kycDetails.bankHolderName) missingFields.push("Bank Account Holder's Name");
    if (!kycDetails.bankIfscCode) missingFields.push("Bank IFSC Code");
    if (!kycDetails.bankBranch) missingFields.push("Bank Branch");

    // Validate files in KycFiles
    if (!KycFiles.aadhaarPhoto) missingFields.push("Aadhaar Photo");
    if (!KycFiles.panPhoto) missingFields.push("PAN Photo");
    if (!KycFiles.bankBookOrChequePhoto) missingFields.push("Bank Book or Cheque Photo");

    // Check account number match
    // if (accountNumber !== retypeAccountNumber) {
    //   toast({
    //     title: "Mismatch Error",
    //     description: "Account numbers do not match. Please correct them.",
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    //   return;
    // }

    // If there are missing fields, show a warning toast
    if (missingFields.length > 0) {
      toast({
        title: "Missing Information",
        description: `Please fill out the following fields: ${missingFields.join(", ")}`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Prepare FormData for submission
    if (isLocal) {
      console.log('isLocal');
    } else {
      const formData = new FormData();

      // Append files only if they have changed
      if (KycFiles.aadhaarPhoto && KycFiles.aadhaarPhotoChanged) {
        formData.append('aadhaarPhoto', KycFiles.aadhaarPhoto);
      }
      if (KycFiles.panPhoto && KycFiles.panPhotoChanged) {
        formData.append('panPhoto', KycFiles.panPhoto);
      }
      if (KycFiles.bankBookOrChequePhoto && KycFiles.bankBookOrChequePhotoChanged) {
        formData.append('bankBookOrChequePhoto', KycFiles.bankBookOrChequePhoto);
      }

      // Add the KYC details as JSON
      const kycJSON = JSON.stringify({ ...kycDetails, bankAccountNumber: accountNumber });
      formData.append('kyc', kycJSON);

      // Make the API request
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error('No auth token found');
        navigate("/user/login");
        return;
      }

      const response = await fetch(`${config.API}/user/kyc/save`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      // Handle API response
      if (response.ok) {
        const data = await response.json();
        setIsSubmitted(true);
        localStorage.setItem("kycSubmitted", "true");
      } else if (response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/user/login");
      } else {
        console.error('KYC submission failed:', response.statusText);
      }
    }
  };


  useEffect(() => {
    setAccountNumber(kycDetails.bankAccountNumber || ""); // Initialize to an empty string if undefined
    setIsAccountNumberChanged(false);
  }, [kycDetails.bankAccountNumber]);

  // const handleAccountNumberChange = (e) => {
  //   const updatedAccountNumber = e.target.value;
  //   setAccountNumber(updatedAccountNumber);

  //   if (retypeAccountNumber) setRetypeAccountNumber("");

  //   setIsAccountNumberChanged(updatedAccountNumber !== kycDetails.bankAccountNumber);
  // };


  const handleRetypeAccountNumberChange = (e) => {
    setRetypeAccountNumber(e.target.value);
  };

  const handleCancelFile = (fieldId) => {
    setKycFiles((prevFiles) => ({
      ...prevFiles,
      [fieldId]: null,
      [`${fieldId}Changed`]: false
    }));
  };

  useEffect(() => {
  }, [filteredDistricts]);



  const handleAccountNumberInput = (e) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove non-digits
    if (input.length <= 16) {
      setAccountNumber(input);
    }
  };




  const renderSection = () => {

    switch (activeSection) {
      case 'identity':
        return (

          <VStack spacing={8} align="start" width="100%">

            <FormControl id="nomineeName" isRequired>
              <FormLabel color={labelColor}>Nominee/Deputy Member Name</FormLabel>
              <Input value={kycDetails.nomineeName} onChange={handleInputChange}
                placeholder="Enter nominee's name" size="lg"
                color={textColor}
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                width="100%" />
            </FormControl>

            <FormControl id="nomineeRelationship" isRequired>
              <FormLabel color={labelColor}>Nominee Relationship</FormLabel>
              <Menu>
                <MenuButton a as={Box}
                  variant="unstyled"
                  rightIcon={<ChevronDownIcon />}
                  color={textColor}
                  width="100%"
                  border="1px solid"
                  borderColor={fieldColor}
                  padding="11px 14px"
                  borderRadius="md"
                  _focus={{ borderColor: "#32B44A" }}
                  _hover={{ borderColor: "#32B44A" }}
                >
                  {kycDetails.nomineeRelationship || "Select relationship"}
                </MenuButton>
                <MenuList bg={menuBg}>
                  <MenuItem onClick={() => handleSelect("mother")} bg={fieldBg} _hover={{ bg: "#32B44A" }}>Mother</MenuItem>
                  <MenuItem onClick={() => handleSelect("father")} bg={fieldBg} _hover={{ bg: "#32B44A" }}>Father</MenuItem>
                  <MenuItem onClick={() => handleSelect("wife")} bg={fieldBg} _hover={{ bg: "#32B44A" }}>Wife</MenuItem>
                  <MenuItem onClick={() => handleSelect("husband")} bg={fieldBg} _hover={{ bg: "#32B44A" }}>Husband</MenuItem>
                  <MenuItem onClick={() => handleSelect("son")} bg={fieldBg} _hover={{ bg: "#32B44A" }}>Son</MenuItem>
                  <MenuItem onClick={() => handleSelect("daughter")} bg={fieldBg} _hover={{ bg: "#32B44A" }}>Daughter</MenuItem>
                  <MenuItem onClick={() => handleSelect("other")} bg={fieldBg} _hover={{ bg: "#32B44A" }}>Other</MenuItem>
                </MenuList>
              </Menu>

            </FormControl>
            <FormControl id="nomineeDob" isRequired>
              <FormLabel color={labelColor}>Nominee Date of Birth</FormLabel>
              <Input value={kycDetails.nomineeDob} onChange={handleInputChange}
                placeholder="Enter nominee's date of birth" type="date" size="lg"
                color={textColor}
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                width="100%" />
            </FormControl>
          </VStack>
        );

      case 'address':
        return (
          <VStack spacing={8} align="start" width="100%">
            <FormControl id="country" isRequired>
              <FormLabel color={labelColor}>Country</FormLabel>
              <Input value={kycDetails.country} size="lg" isReadOnly
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
                width="100%" />
            </FormControl>

            <FormControl id="state" isRequired>
              <FormLabel color={labelColor}>State</FormLabel>
              <Input value={kycDetails.state} size="lg" isReadOnly
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
                width="100%" />
            </FormControl>

            <FormControl id="district" isRequired>
              <FormLabel color={labelColor}>District</FormLabel>

              {/* Dropdown for filtered districts */}
              <Menu>
                <MenuButton
                  as={Box}
                  variant="unstyled"
                  rightIcon={<ChevronDownIcon />}
                  color={textColor}
                  width="100%"
                  border="1px solid"
                  borderColor={fieldColor}
                  padding="10px 14px"
                  borderRadius="md"
                  minHeight="48px"
                  _focus={{ borderColor: "#32B44A" }}
                  _hover={{ borderColor: hoverBorderColor }}
                >
                  {kycDetails.district || "Select district"}
                </MenuButton>
                <MenuList
                  bg={menuBg}
                  maxHeight="250px"
                  overflowY="auto"
                  width="100%"
                  borderRadius="md"
                >
                  {filteredDistricts.map((district) => (
                    <MenuItem
                      key={district}
                      onClick={() => handleSelectDistrict(district)}
                      bg={fieldBg}
                      _hover={{ bg: "#32B44A" }}
                    >
                      {district}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl id="streetAddress" isRequired>
              <FormLabel color={labelColor}>Street Address</FormLabel>
              <Textarea
                id="address" // Matches key in state
                value={kycDetails.address}
                onChange={handleInputChange}
                placeholder="Enter your street address"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
                width="100%"
              />
            </FormControl>

            {/* Pin Code */}
            <FormControl id="pinCode" isRequired>
              <FormLabel color={labelColor}>Pin Code</FormLabel>
              <Input
                value={kycDetails.pinCode || ''} // Default to empty string if null/undefined
                onChange={handleInputChange} // Common handler
                placeholder="Enter pin code"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
              {kycDetails.pinCode && !/^\d{6}$/.test(kycDetails.pinCode) && (
                <Text color="red.500" mt={2}>
                  Pin code must be 6 digits.
                </Text>
              )}
            </FormControl>
          </VStack>
        );

      case 'fileUpload':
        return (
          <VStack spacing={8} align="start" width="100%">
            <FormControl id="aadhaarPhoto" isRequired>
              <FormLabel color={labelColor}>Upload Aadhaar Photo</FormLabel>
              <Input
                id="aadhaarPhoto"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                display="none"
              />
              <Button as="label"
                htmlFor="aadhaarPhoto"
                width={{ base: "50%", md: "50%", lg: "25%" }}
                variant="outline"
                leftIcon={<FiUpload />}
                sx={{
                  bg: "#32B44A",
                  color: "white",
                  _hover: {
                    bg: "#32B44A",
                    color: "white",
                    borderColor: "#32B44A",
                  },
                  _focus: {
                    borderColor: "#32B44A",
                  },
                }}>
                Upload
              </Button>

              <Flex width="100%" align="center" mt={2} justify="space-between">
                {kycDetails.aadhaarPhoto && (
                  <Text color="green.500" fontSize="sm">
                    {kycDetails.aadhaarPhoto}
                  </Text>
                )}

                {KycFiles.aadhaarPhoto && (
                  <Center flex="1">
                    <Text fontSize="sm" color="green.500" fontWeight="bold" mx={2}>
                      Uploaded {KycFiles.aadhaarPhoto.name}
                    </Text>
                    <Button
                      size="xs"
                      colorScheme="red"
                      variant="solid"
                      borderRadius="full"
                      onClick={() => handleCancelFile("aadhaarPhoto")}
                      ml={2}
                    >
                      X
                    </Button>
                  </Center>
                )}
              </Flex>
            </FormControl>

            <FormControl id="panPhoto" isRequired>
              <FormLabel color={labelColor}>Upload PAN Photo</FormLabel>
              <Input
                id="panPhoto"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                display="none"
              />
              <Button as="label" htmlFor="panPhoto" width={{ base: "50%", md: "50%", lg: "25%" }}
                variant="outline"
                leftIcon={<FiUpload />}
                sx={{
                  bg: "#32B44A",
                  color: "white",
                  _hover: {
                    bg: "#32B44A",
                    color: "white",
                    borderColor: "#32B44A",
                  },
                  _focus: {
                    borderColor: "#32B44A",
                  },
                }}>
                Upload
              </Button>

              <Flex width="100%" align="center" mt={2} justify="space-between">
                {kycDetails.panPhoto && (
                  <Text color="green.500" fontSize="sm">
                    {kycDetails.panPhoto}
                  </Text>
                )}

                {KycFiles.panPhoto && (
                  <Center flex="1">
                    <Text fontSize="sm" color="green.500" fontWeight="bold" mx={2}>
                      Uploaded {KycFiles.panPhoto.name}
                    </Text>
                    <Button
                      size="xs"
                      colorScheme="red"
                      variant="solid"
                      borderRadius="full"
                      onClick={() => handleCancelFile("panPhoto")}
                      ml={2}
                    >
                      X
                    </Button>
                  </Center>
                )}
              </Flex>
            </FormControl>

            <FormControl id="bankBookOrChequePhoto" isRequired>
              <FormLabel color={labelColor}>Upload Bank Book/Cheque Photo</FormLabel>
              <Input
                id="bankBookOrChequePhoto"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                display="none"
              />
              <Button as="label" htmlFor="bankBookOrChequePhoto" width={{ base: "50%", md: "50%", lg: "25%" }}
                variant="outline"
                leftIcon={<FiUpload />}
                sx={{
                  bg: "#32B44A",
                  color: "white",
                  _hover: {
                    bg: "#32B44A",
                    color: "white",
                    borderColor: "#32B44A",
                  },
                  _focus: {
                    borderColor: "#32B44A",
                  },
                }}>
                Upload
              </Button>

              <Flex width="100%" align="center" mt={2} justify="space-between">
                {kycDetails.bankBookOrChequePhoto && (
                  <Text color="green.500" fontSize="sm">
                    {kycDetails.bankBookOrChequePhoto}
                  </Text>
                )}
                {KycFiles.bankBookOrChequePhoto && (
                  <Center flex="1">
                    <Text fontSize="sm" color="green.500" fontWeight="bold" mx={2}>
                      Uploaded {KycFiles.bankBookOrChequePhoto.name}
                    </Text>
                    <Button
                      size="xs"
                      colorScheme="red"
                      variant="solid"
                      borderRadius="full"
                      onClick={() => handleCancelFile("bankBookOrChequePhoto")}
                      ml={2}
                    >
                      X
                    </Button>
                  </Center>
                )}
              </Flex>
            </FormControl>
            {/* Aadhaar Number */}
            <FormControl id="aadhaarNumber" isRequired>
              <FormLabel color={labelColor}>Aadhaar Number</FormLabel>
              <Input
                value={kycDetails.aadhaarNumber || ''} // Default to empty string if null/undefined
                onChange={handleInputChange}
                placeholder="Enter Aadhaar number"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
              {kycDetails.aadhaarNumber?.replace(/\s/g, '').length !== 12 && (
                <Text color="red.500" mt={2}>
                  Aadhaar number must be 12 digits.
                </Text>
              )}
            </FormControl>

            {/* PAN */}
            <FormControl id="pan" isRequired>
              <FormLabel color={labelColor}>PAN Card Number</FormLabel>
              <Input
                value={kycDetails.pan || ''} // Default to empty string if null/undefined
                onChange={handleInputChange}
                placeholder="Enter PAN card number"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
              {kycDetails.pan && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(kycDetails.pan) && (
                <Text color="red.500" mt={2}>
                  PAN must be in format ABCDE1234F.
                </Text>
              )}
            </FormControl>

            {/* Bank Name */}
            <FormControl id="bankName" isRequired>
              <FormLabel color={labelColor}>Bank Name</FormLabel>
              <Input
                value={kycDetails.bankName}
                onChange={handleInputChange} // Common handler
                placeholder="Enter bank name"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
            </FormControl>

            {/* Bank Holder Name */}
            <FormControl id="bankHolderName" isRequired>
              <FormLabel color={labelColor}>Bank Holder Name</FormLabel>
              <Input
                value={kycDetails.bankHolderName}
                onChange={handleInputChange} // Common handler
                placeholder="Enter account holder name"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
            </FormControl>

            {/* Account Number */}
            <FormControl id="bankAccountNumber" isRequired>
              <FormLabel color={labelColor}>Account Number</FormLabel>
              <Input
                value={accountNumber}
                onChange={handleAccountNumberInput} // Separate logic if needed for this field
                placeholder="Enter account number"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
              {accountNumber && (accountNumber.length < 11 || accountNumber.length > 16) && (
                <Text color="red.500" mt={2}>
                  Account number must be between 11 and 16 digits.
                </Text>
              )}
            </FormControl>

            {/* IFSC Code */}
            <FormControl id="bankIfscCode" isRequired>
              <FormLabel color={labelColor}>IFSC Code</FormLabel>
              <Input
                value={kycDetails.bankIfscCode || ''} // Default to empty string if null/undefined
                onChange={handleInputChange} // Common handler with real-time validation
                placeholder="Enter IFSC code"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
              {kycDetails.bankIfscCode && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(kycDetails.bankIfscCode) && (
                <Text color="red.500" mt={2}>
                  IFSC code must be 11 digits & in the format AAAA0BBBBBB (4 letters, 0, and 6 alphanumeric characters).
                </Text>
              )}
            </FormControl>





            {/* Render retype field dynamically */}
            {accountNumber && isAccountNumberChanged && (
              <FormControl id="retypeAccountNumber" isRequired mt={4}>
                <FormLabel color={labelColor}>Retype Account Number</FormLabel>
                <Input
                  value={retypeAccountNumber}
                  onChange={handleRetypeAccountNumberChange}
                  placeholder="Re-enter account number"
                  size="lg"
                  focusBorderColor={inputBorderColor}
                  hoverBorderColor={hoverBorderColor}
                  color={textColor}
                />
              </FormControl>
            )}

            {/* Show error message if account numbers do not match */}
            {isAccountNumberChanged && accountNumber !== retypeAccountNumber && retypeAccountNumber && (
              <Text color="red.500" mt={2}>
                Account numbers do not match!
              </Text>
            )}


            {/* Branch Name */}
            <FormControl id="branchName" isRequired>
              <FormLabel color={labelColor}>Branch</FormLabel>
              <Input
                value={kycDetails.branchName || ''} // Default to empty string if null/undefined
                onChange={handleInputChange} // Common handler with real-time validation
                placeholder="Enter branch name"
                size="lg"
                focusBorderColor={inputBorderColor}
                hoverBorderColor={hoverBorderColor}
                color={textColor}
              />
            </FormControl>


          </VStack>
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (activeSection === "identity") {
      setActiveSection("address");
    } else if (activeSection === "address") {
      setActiveSection("fileUpload");
    }
  };

  return (
    <Box p={6} bg={formBg} borderRadius="md" boxShadow="md" mt={2}>
      <VStack spacing={8} align="start">
        <Flex
          width="100%"
          justify={{ base: 'center', md: 'space-between' }}
          align="center"
          direction={{ base: 'column', md: 'row' }}
        >
          {/* Stack for buttons */}
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            width="100%"
            align={{ base: 'center', md: 'start' }}
          >
            <Button
              onClick={() => setActiveSection('identity')}
              colorScheme={activeSection === 'identity' ? "green" : "gray"}
              width={{ base: '100%', md: 'auto' }}
              size={{ base: 'lg', md: 'md' }}
            >
              Nominee details
            </Button>
            <Button
              onClick={() => setActiveSection('address')}
              colorScheme={activeSection === 'address' ? "green" : "gray"}
              width={{ base: '100%', md: 'auto' }}
              size={{ base: 'lg', md: 'md' }}
            >
              Address details
            </Button>
            <Button
              onClick={() => setActiveSection('fileUpload')}
              colorScheme={activeSection === 'fileUpload' ? "green" : "gray"}
              width={{ base: '100%', md: 'auto' }}
              size={{ base: 'lg', md: 'md' }}
            >
              Files & Bank Details
            </Button>
          </Stack>
          <Box
            mt={{ base: 4, md: 0 }}
            ml={{ base: 0, md: 4 }}
          >
            <Flex align="center" width="100%" justify="flex-start">
              <Text ml={1}><strong>Status:</strong></Text>
              <Text color={kycDetails.status === "VERIFICATION_REQUESTED" ? "orange" : "green"} whiteSpace="nowrap" ml={2}
                mr={2}
                textAlign={{ base: "left", md: "left" }} >
                {labels[kycDetails.status]}
              </Text>
            </Flex>
          </Box>
        </Flex>

        {renderSection()}

        <Stack direction="row" spacing={4} width="100%" justify="space-between" mt={4}>
          {activeSection !== 'identity' && (
            <Button onClick={handleBack} bg="#32B44A"
              _hover={{ bg: "#32B44A", color: "white" }}
              _focus={{ bg: "#32B44A", color: "white" }}
              _active={{ bg: "#32B44A", color: "white" }}>
              Back
            </Button>
          )}
          <Box flex="1" textAlign="right">
            {activeSection === 'fileUpload' ? (
              <>
                <Button onClick={handleSubmit} bg="#32B44A"
                  _hover={{ bg: "#32B44A", color: "white" }}
                  _focus={{ bg: "#32B44A", color: "white" }}
                  _active={{ bg: "#32B44A", color: "white" }} size="lg"
                >
                  Submit
                </Button>
                {isSubmitted && (
                  <Center>
                    <Text color="green.600" fontWeight="bold">
                      KYC Submitted
                    </Text>
                  </Center>
                )}
                <Center mt={4} width="100%">
                  <VStack>
                    {/* Verify KYC Button (Always visible, but state changes based on conditions) */}
                    <Button
                      bg={kycDetails?.status?.trim() === "VERIFICATION_PENDING" || kycDetails?.status?.trim() === "VERIFICATION_REQUESTED" || kycDetails?.status?.trim() === "VERIFICATION_REJECTED" || !kycDetails?.status ? "#32B44A" : "gray.400"}
                      ml={4}
                      _hover={{ bg: "#32B44A", color: "white" }}
                      _focus={{ bg: "#32B44A", color: "white" }}
                      _active={{ bg: "#32B44A", color: "white" }}
                      size="lg"
                      onClick={verifyKYC}
                      isLoading={isLoading}
                      isDisabled={isLoading || isKYCUnderReview || !kycDetails?.status || !(kycDetails?.status.trim() === "VERIFICATION_PENDING" || kycDetails?.status.trim() === "VERIFICATION_REJECTED" || kycDetails?.status.trim() === "VERIFICATION_REQUESTED")}
                    >
                      {kycDetails?.status?.trim() === "VERIFICATION_PENDING" || kycDetails?.status?.trim() === "VERIFICATION_REJECTED" || kycDetails?.status?.trim() === "VERIFICATION_REQUESTED"
                        ? "Verify KYC"
                        : "KYC Verified"}
                    </Button>


                    {/* Show under review message */}
                    {isKYCUnderReview && (
                      <Text color="green.600" fontWeight="bold">
                        Your KYC is under review
                      </Text>
                    )}
                  </VStack>


                </Center>
              </>
            ) : (
              <Button onClick={handleNext} bg="#32B44A"
                _hover={{ bg: "#32B44A", color: "white" }}
                _focus={{ bg: "#32B44A", color: "white" }}
                _active={{ bg: "#32B44A", color: "white" }}>
                Next
              </Button>
            )}
          </Box>
        </Stack>
      </VStack>
    </Box>
  );
};

export default FormPage;
