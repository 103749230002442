import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Icon,
  Image,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { MdShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import config from '../../../../config.json';
import myPurchaseBgImage from 'assets/img/dashboards/mypurchasebg.png';

const isLocal = config.isLocal;

export default function MyOrders(props) {
  const { ...rest } = props;

  const [orders, setOrders] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrders = async () => {
    setIsLoading(true);
    if (isLocal) {
      try {
        const response = await fetch('/data/orders.json');
        if (!response.ok) throw new Error('Failed to fetch orders');
        const data = await response.json();
        setOrders(data.orders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    } else {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(`${config.API}/order/get/all`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (response.status === 200) {
          const data = await response.json();
          setOrders(data.orders);
        } else if (response.status === 401) {
          localStorage.removeItem("authToken");
          window.location.href = "/user/login";
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!hasFetched) {
      fetchOrders();
      setHasFetched(true);
    }
  }, [hasFetched]);

  const handleViewAllClick = () => {
    navigate('/user/orders', { state: { orders } });
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("#32B44A", "#32B44A");
  const navigate = useNavigate();

  return (
    <Card
      p={{ base: "10px", md: "20px" }}
      align="center"
      direction="column"
      w="100%"
      mb={{ base: "10px", md: "0" }}
      {...rest}
    >
      <Flex alignItems="center" w="100%" mb={{ base: "20px", md: "30px" }}>
        <Icon
          as={MdShoppingCart}
          color={brandColor}
          w={{ base: "28px", md: "38px" }}
          h={{ base: "28px", md: "38px" }}
          me={{ base: "8px", md: "12px" }}
          bg={boxBg}
          p="8px"
          borderRadius="full"
        />
        <Text color={textColor} fontSize={{ base: "xl", md: "lg" }} fontWeight="700">
          My Orders
        </Text>
      </Flex>

      <Box
        w="100%"
        mb={{ base: "20px", md: "30px" }}
        p="15px"
        bg="linear-gradient(90deg, rgba(189,255,122,1) 0%, rgba(107,206,118,1) 100%)"
        borderRadius="12px"
        align="center"
        justify="center"
      >
        <Flex align="center" w="100%" direction={{ base: "column", md: "row" }}>
          <Image
            src={myPurchaseBgImage}
            alt="Background"
            boxSize={{ base: "40px", md: "80px" }}
            mr={{ base: "0", md: "10px" }}
            mb={{ base: "10px", md: "0" }}
          />
          <Box>
            <Text color={"#1B2559"} fontSize={{ base: "xl", md: "lg" }} fontWeight="bold" >
              Recent Orders
            </Text>
          </Box>
        </Flex>
      </Box>

      {/* Show spinner if loading */}
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" h="100px">
          <Spinner
            thickness="4px"
            speed="0.75s"
            color="#32B44A"
            size="xl"
          />
        </Flex>
      ) : orders.length > 0 ? (
        orders.slice(0, 3).map((order, index) => (
          <Box key={index} w="100%">
            <Flex
              align={{ base: "center", md: "center" }}
              justify={{ base: "space-between", md: "center" }}
              borderWidth="1px"
              borderRadius="12px"
              p="10px"
              mb="10px"
              direction={{ base: "column", md: "row" }}
            >
              <Box mb={{ base: "10px", md: "0" }} textAlign="center">
                <Text fontWeight="bold" fontSize={{ base: "xl", md: "md" }} color={textColor}>
                  Order ID: {order.orderId}
                </Text>
                <Text fontSize={{ base: "xl", md: "md" }} color={textColor} fontWeight="bold">
                <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>{order.totalPrice}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))
      ) : (
        <Text>No orders available.</Text>
      )}

      <Text
        fontWeight="bold"
        fontSize={{ base: "xl", md: "md" }}
        color={brandColor}
        textAlign="center"
        cursor="pointer"
        mt={{ base: "10px", md: "20px" }}
        onClick={handleViewAllClick}
      >
        View All
      </Text>
    </Card>
  );
}
