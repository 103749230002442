// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';

import React, { useState, useEffect } from 'react';

import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import LogoutComponent from 'components/model/LogoutConfirmationModal';

// Custom Components
import { ItemContent } from 'components/menu/ItemContent';

import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { IoCart } from "react-icons/io5";
import { useCart } from 'components/CartContext/CartContext';
import routes from 'routes';
import CartModal from 'components/CartModel/CartModel';

export default function HeaderLinks(props) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const [loggedOut, setLoggedOut] = useState(false);
  const { cartItems, getCart } = useCart();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState('');

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setName(parsedUserData.fullName);
    }
  }, []);
  

  const handleLogout = () => {
    setLoggedOut(true);
  };


  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
      gap='5%'
    >
      {/* Sidebar for mobile responsive */}
      <SidebarResponsive routes={routes} />





      {/* Theme Toggle */}
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>
      {/* User Menu */}
      <Menu>
        <MenuButton p="0px">
          {!loggedOut && (
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              name={name}
              bg="#32B44A"
              size="sm"
              w="40px"
              h="40px"
            />
          )}
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {!loggedOut && (name || 'User')}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">

            {/* Logout Option */}
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <LogoutComponent onLogout={handleLogout} />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
      <Button id='cart'
        leftIcon={<Icon as={IoCart} />}
        bg="#32B44A"
        _hover="#32B44A"
        _active="#32B44A"
        color="white"
        onClick={onOpen}
      >({cartItems.length})
      </Button>
      <CartModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
}

