import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdHome,
  MdLogin,
  MdPerson,
  MdSettings,
  MdStore,
  MdShoppingCart,
  MdTrendingUp,
} from 'react-icons/md';
import Dash from 'User-dashboard/Dashboard/Dash';
import Profile from 'User-dashboard/MyProfile/MyProfile';
import Login from 'User-dashboard/Login/Login';
import Register from 'User-dashboard/Register/register';
import Settings from 'User-dashboard/Settings/Settings';
import IncomeReport from 'User-dashboard/IncomeReport/Income';
import OrderProducts from 'User-dashboard/OrderProducts/OrderProduct'
import ProductDetail from 'User-dashboard/ProductDetail/ProductDetail';
import Order from 'User-dashboard/Dashboard/Order';
import Reset from 'views/auth/signIn/ResetPassword';

const green = '#32B44A';
const routes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    layout: '/user',
    icon: <Icon as={MdHome} width="20px" height="20px" color={green} />,
    component: <Dash />,
  },
  {
    name:'My Profile',
    path:'/profile',
    layout:'/user',
    icon: <Icon as={MdPerson} width="20px" height="20px" color={green} />,
    component: <Profile />,
  },
  // {
  //   name: 'My Team',
  //   path: '/myTeam',
  //   layout: '/user',
  //   icon: <Icon as={MdGroup} width="20px" height="20px" color={green} />,
  // },
  {
    name: 'Order Products',
    path: '/products',
    layout: '/user',
    icon: <Icon as={MdStore} width="20px" height="20px" color={green} />,
    component: <OrderProducts />,
  },
  {
    name: 'Income',
    path: '/income',
    layout: '/user',
    icon: <Icon as={MdTrendingUp} width="20px" height="20px" color={green} />,
    component:<IncomeReport />
  },
  {
    name: 'My Orders',
    path: '/orders',
    layout: '/user',
    icon: <Icon as={MdShoppingCart} width="20px" height="20px" color={green} />,
    component: <orders />,
  },
  //{
   // name : 'KYC',
   // path: '/kyc',
   // layout: '/user',
   // icon: <Icon as={MdPerson} width="20px" height="20px" color={green} />,
   // component: <Kyc />,
   
 // },
  // {
  //   name: 'Chats',
  //   path: '/chats',
  //   layout: '/user',
  //   icon: <Icon as={MdChat} width="20px" height="20px" color={green} />,
  // },
  
  {
    name: 'Settings',
    path: '/settings',
    layout: '/user',
    icon: <Icon as={MdSettings} width="20px" height="20px" color={green} />,
    component:<Settings />,
    
  },
  
  {
    name: 'Login',
    path: '/login',
    layout: '/user',
    icon: <Icon as={MdLogin} width="20px" height="20px" color={green} />,
    component: <Login />,
    hidden: true, // Mark as hidden
  },
  {
    name : 'Register',
    path: '/register',
    layout: '/user',
    icon: <Icon as={MdLogin} width="20px" height="20px" color={green} />,
    component: <Register />,
    hidden: true, // Mark as hidden
  },
  {
    name : 'ProductDetail',
    path: '/productdetail',
    layout: '/user',
    icon: <Icon as={MdLogin} width="20px" height="20px" color={green} />,
    component: <ProductDetail />,
    hidden: true, // Mark as hidden
  },
  {
    name : 'Order',
    path: '/order',
    layout: '/user',
    icon: <Icon as={MdLogin} width="20px" height="20px" color={green} />,
    component: <Order />,
    hidden: true, // Mark as hidden
  },
  {
    name : 'Reset Password',
    path: '/resetPassword',
    layout: '/user',
    component: <Reset />,
    hidden: true, // Mark as hidden
  },
];


export default routes;
