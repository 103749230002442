import {
  Box,
  Collapse,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import config from "../../config.json";

const IncomeReport = () => {
  const [incomeData, setIncomeData] = useState([]);
  const [expandedWeekId, setExpandedWeekId] = useState(null);
  const [weekIds, setWeekIds] = useState([]);
  const [loading, setLoading] = useState(false); 
    const [ setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({});
  const [weekIdsPagination, setWeekIdsPagination] = useState({
    currentPage: 0,
    totalPages: 0,
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Format time
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Combine date and time
    return `${day}-${month}-${year} , ${hours} : ${minutes} : ${seconds}`;
  };


  // Fetch the list of week IDs
  const fetchWeekIds = async (page = 0) => {
    const token = localStorage.getItem("authToken");
    const isLocal = config.isLocal;
    const pageSize = 10;
    try {
      let data = [];
      if (isLocal) {
        const response = await fetch("/incomeData.json");
        data = response.ok ? await response.json() : [];
      } else {
        const response = await fetch(`${config.API}/user/income/get`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          window.location.href = "/user/login";
          return;
        }
        if (response.ok) {
          const responseData = await response.json();
          data = responseData.weekIds || [];
        } else {
          console.error("Error fetching week IDs");
        }
      }
      const totalPages = Math.ceil(data.length / pageSize);
      setWeekIdsPagination({ currentPage: page, totalPages });

      const currentWeekId = data.slice(page * pageSize, (page + 1) * pageSize);
      setWeekIds(currentWeekId);
    } catch (error) {
      console.error("Error fetching week IDs:", error);
    }
  };
  useEffect(() => {
    fetchWeekIds();
  }, []);

  const handleWeekIdsPagination = (direction) => {
    const nextPage = weekIdsPagination.currentPage + direction;
    if (nextPage >= 0 && nextPage < weekIdsPagination.totalPages) {
      fetchWeekIds(nextPage);
    }
  };

  // Fetch income details for a specific week ID
  const fetchIncomeDetails = async (weekId, page = 0) => {
    const token = localStorage.getItem("authToken");
    const isLocal = config.isLocal;
    setLoading(true);
    try {
      let weekIncomeData = [];
      if (isLocal) {
        const response = await fetch("/incomeDetails.json");
        const data = response.ok ? await response.json() : [];
        weekIncomeData = Array.isArray(data) ? data.filter((entry) => entry.weekId === weekId) : [];
        setTotalPages(Math.ceil(data.filter(entry => entry.weekId === weekId).length / 10));
        setPagination((prev) => ({
          ...prev,
          [weekId]: {
            currentPage: page,
            totalPages: Math.ceil(weekIncomeData.length / 10),
          },
        }));
      } else {
        const response = await fetch(
          `${config.API}/user/income/get/${weekId}?page=${page}&pageSize=10`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          window.location.href = "/user/login";
          return;
        }
        if (response.ok) {
          const responseData = await response.json();
          weekIncomeData = responseData.income || [];
          setPagination((prev) => ({
            ...prev,
            [weekId]: {
              currentPage: page,
              totalPages: responseData.totalPages,
            },
          }));
        } else {
          console.error(`Error fetching income details for week ID: ${weekId}`);
        }
      }
      setIncomeData((prevData) => [
        ...prevData.filter((entry) => entry.weekId !== weekId),
        ...weekIncomeData,
      ]);
    } catch (error) {
      console.error("Error fetching income details:", error);
    }
    setLoading(false);
  };


  const toggleDetail = (weekId) => {
    if (expandedWeekId === weekId) {
      setExpandedWeekId(null);
    } else {
      setExpandedWeekId(weekId);
      fetchIncomeDetails(weekId, 0);
    }
  };

  // Group data by week ID for easy rendering
  const groupedData = incomeData.reduce((acc, entry) => {
    const weekId = entry.weekId;
    if (!acc[weekId]) {
      acc[weekId] = { entries: [], totalIncome: 0 };
    }
    acc[weekId].entries.push(entry);
    acc[weekId].totalIncome += entry.bvIncome;
    return acc;
  }, {});

  const bgColor = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("gray.800", "white");
  const oddRowColor = useColorModeValue("#F0FFF4", "#4A5568");
  const headColor = useColorModeValue("white", "white");

  const rowColor = useColorModeValue("#C6F6D5", "#2D3748");

  return (
    <Box
      mt="90px"
      p={4}
      borderWidth={1}
      borderRadius="20px"
      transition="all 0.3s ease"
      boxShadow="lg"
      bg={bgColor}
      width={{ base: "95%", md: "80%", lg: "95%" }}
      mx="auto"
      minHeight="500px"
    >
      <Text
        fontSize={{ base: "xl", md: "2xl" }}
        fontWeight="bold"
        color={textColor}
        mb={4}
        textAlign="center"
      >
        My Income
      </Text>

      <Box overflowY="auto" maxHeight="60vh">
        {weekIds.map((weekId) => (
          <Box key={weekId} mb={4}>
            <Flex
              justify="space-between"
              align="center"
              onClick={() => toggleDetail(weekId)}
              p={3}
              border="1px solid #32B44A"
              borderRadius="4px"
              bg={oddRowColor}
              cursor="pointer"
            >
              <Text color={textColor} fontSize={{ base: "md", md: "lg" }}>
                {weekId}
              </Text>
            </Flex>

            <Collapse in={expandedWeekId === weekId}>
              <Box overflowX="auto" mt={2}>
                <Table width="100%" size="sm" >
                  <Thead>
                    <Tr>
                      <Th color={headColor} textAlign="left" bg="#32B44A" p={3}>
                        Date , Time
                      </Th>
                      <Th color={headColor} textAlign="left" bg="#32B44A">
                        Week ID
                      </Th>
                      <Th color={headColor} textAlign="left" bg="#32B44A">
                        User ID
                      </Th>
                      <Th color={headColor} textAlign="left" bg="#32B44A">
                        BV Income (₹)
                      </Th>
                      <Th color={headColor} textAlign="left" bg="#32B44A">
                        Downline Level
                      </Th>
                      <Th color={headColor} textAlign="left" bg="#32B44A">
                        Source
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {groupedData[weekId]?.entries?.length > 0 ? (
                      groupedData[weekId].entries.map((entry, index) => (
                        <Tr key={index} bg={index % 2 === 0 ? rowColor : bgColor}>
                          <Td>
                            <Flex align="center">
                              <Text>{formatDate(entry.createdAt)}</Text>
                            </Flex>
                          </Td>
                          <Td>{entry.weekId}</Td>
                          <Td>{entry.userId}</Td>
                          <Td>{entry.bvIncome}</Td>
                          <Td>{entry.downlineLevel}</Td>
                          <Td>{entry.source}</Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan={7} textAlign="center">
                          No data available for this week.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Box>
              <Box mt={4} textAlign="center">
                <Flex justify="center" mt={2} gap={2}>
                  <Button
                    variant="outline"
                    colorScheme="teal"
                    size="sm"
                    isDisabled={pagination[weekId]?.currentPage === 0}
                    onClick={() => fetchIncomeDetails(weekId, pagination[weekId].currentPage - 1)}
                  >
                    ← Previous
                  </Button>

                  <Text mt={2} fontSize="sm">
                    Page {pagination[weekId]?.currentPage + 1} of {pagination[weekId]?.totalPages}
                  </Text>

                  <Button
                    variant="outline"
                    colorScheme="teal"
                    size="sm"
                    isDisabled={pagination[weekId]?.currentPage + 1 >= pagination[weekId]?.totalPages}
                    onClick={() => fetchIncomeDetails(weekId, pagination[weekId].currentPage + 1)}
                  >
                    Next →
                  </Button>
                </Flex>
              </Box>

            </Collapse>
          </Box>
        ))}
      </Box>
      {/* Conditionally Render WeekIds Pagination Controls */}
      {expandedWeekId === null && (
        <Box mt={2} textAlign="center" bottom="0" left="0" width="100%" p={2}>
          <Flex justify="center" gap={4}>
            <Button
              variant="outline"
              colorScheme="teal"
              isDisabled={weekIdsPagination.currentPage === 0}
              onClick={() => handleWeekIdsPagination(-1)}
              size="sm"
            >
              ← Previous
            </Button>
            <Text mt={1} size="sm">
              Page {weekIdsPagination.currentPage + 1} of {weekIdsPagination.totalPages}
            </Text>
            <Button
              variant="outline"
              colorScheme="teal"
              isDisabled={weekIdsPagination.currentPage === weekIdsPagination.totalPages - 1}
              onClick={() => handleWeekIdsPagination(1)}
              size="sm"
            >
              Next →
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default IncomeReport;
