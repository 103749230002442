import React from "react";
import { Box, Text, useColorModeValue, Flex } from "@chakra-ui/react";
import PersonalInfo from "User-dashboard/MyProfile/PersonalInfo";
import Kyc from "User-dashboard/MyProfile/Kyc";
//removed unused line , for resolving all the errors in the terminal
export default function Overview() {
  const headingColor = useColorModeValue("gray.700", "gray.200");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex
          direction={{ base: "column", lg: "row" }}
          justifyContent="space-between"  
          gap={{ base: "20px", xl: "20px" }}
          width="100%"
        >
        {/* PersonalInfo component */}
        <PersonalInfo
          gridColumn={{ base: "1 / 2", lg: "2 / 3" }} // Place Storage next to Profile on larger screens
          gridRow="1 / 2"
          width="100%"
        />
        </Flex>

      {/* KYC component */}
      <Box mt="5px">
        <Text fontSize="2xl" fontWeight="bold" color={headingColor} mb="0" mt="4">
          KYC Details
        </Text>
        <Kyc mt="-20px" />
      </Box>
    </Box>
  );
}
