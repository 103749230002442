import React from "react";
import jsPDF from "jspdf";

const Invoice = ({ orderDetails }) => {
  const handleDownloadInvoice = () => {
    const doc = new jsPDF();

    // Set green theme color
    const greenColor = [34, 139, 34];

    // Company Header
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(...greenColor);
    doc.setFontSize(24);
    doc.text("Geearr Consumer Solution", 20, 20);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0); 
    doc.text("Address: 123 Main Street, City, State", 20, 28);
    doc.text("Email: support@yourcompany.com", 20, 34);
    doc.text("Phone: +123-456-7890", 20, 40);

    // Invoice Header
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(...greenColor);
    doc.setFontSize(18);
    doc.text("INVOICE", 150, 20);

    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0); // Black for metadata
    doc.text(`Invoice No: ${orderDetails.orderId}`, 150, 28);
    doc.text(`Date: ${orderDetails.createdAt}`, 150, 34);
    doc.text(`Payment Type: ${orderDetails.paymentType}`, 150, 40);

    // Customer Details
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(...greenColor);
    doc.text("Billed To:", 20, 60);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0); // Black for metadata
    doc.text(`User ID: ${orderDetails.userId}`, 20, 68);

    // Line Separator
    doc.setDrawColor(...greenColor);
    doc.line(20, 75, 190, 75);

    // Table Header
    const tableTop = 85;
    const colWidths = [10, 100, 25, 25, 25];
    const headers = ["#", "Product Name", "MRP", "Qty", "Total"];

    doc.setFont("Helvetica", "bold");
    doc.setTextColor(...greenColor);
    let xPos = 20;
    headers.forEach((header, index) => {
      doc.text(header, xPos, tableTop);
      xPos += colWidths[index];
    });

    // Order Items
    let yPos = tableTop + 8;
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(0);
    orderDetails.items.forEach((item, index) => {
      const product = item.product;
      xPos = 20;

      const row = [
        index + 1,
        product.name,
        `${product.discountPrice}`,
        item.quantity,
        `${item.totalPrice}`,
      ];

      row.forEach((data, colIndex) => {
        doc.text(String(data), xPos, yPos);
        xPos += colWidths[colIndex];
      });

      yPos += 8;
    });

    // Line Separator After Table
    doc.setDrawColor(...greenColor);
    doc.line(20, yPos + 2, 190, yPos + 2);

    // Total Section
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(...greenColor);
    doc.text("Total Amount:", 140, yPos + 10);
    doc.setTextColor(0);
    doc.text(`${orderDetails.totalPrice}`, 190, yPos + 10, { align: "right" });

    // Footer
    doc.setFont("Helvetica", "italic");
    doc.setFontSize(10);
    doc.text("Thank you for your business!", 20, 280);
    doc.text("If you have any questions, contact us at support@yourcompany.com.", 20, 286);

    doc.save(`Invoice_${orderDetails.orderId}.pdf`);
  };

  return (
    <button
      onClick={handleDownloadInvoice}
      style={{
        marginTop: "20px",
        padding: "10px 20px",
        backgroundColor: "#4CAF50",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Download Invoice
    </button>
  );
};

export default Invoice;
