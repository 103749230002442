import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("#262626", "white");
  //Pdf Urls
  const privacyPolicyUrl = "/assets/pdf/privacy-policy.pdf";
  const termsAndConditionsUrl = "/assets/pdf/terms-and-conditions.pdf";
  const shippingPolicyUrl = "/assets/pdf/shipping-policy.pdf";
  const cancellationPolicyUrl = "/assets/pdf/cancellation-policy.pdf";

  return (
    <Flex
      zIndex="3"
      flexDirection={{ base: "column", xl: "row" }} // Stack vertically on small screens, row on large
      alignItems="center"
      justifyContent="space-between"
      px={{ base: "30px", md: "50px" }}
      pb="30px"
      textAlign={{ base: "center", xl: "left" }} // Center text on mobile
    >
      <Text
        color={textColor}
        mb={{ base: "20px", xl: "0px" }} // Add margin on mobile for spacing
        fontSize={{ base: "sm", md: "md" }} // Adjust font size for mobile
      >
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          GRCS. All Rights Reserved. Made with love by
          <Link
            mx="3px"
            color={textColor}
            href="https://www.smartside.in/"
            target="_blank"
            fontWeight="700"
            _hover={{
              color: "#32B44A", // Change color on hover
            }
            }
          >
            SmartSide!
          </Link>
        </Text>
      </Text>

      <List
        display="flex"
        flexDirection={{ base: "row", md: "row" }} // Stack links vertically on mobile
        alignItems="center"
        justifyContent="center"
        gap={{ base: "10px", md: "44px" }} // Add spacing between links on mobile
      >
        <List display="flex" gap="20px" flexDirection={{ base: "column", md: "row" }}>
        <ListItem>
          <Link
            color={textColor}
            href={privacyPolicyUrl}
            target="_blank"
            fontWeight="500"
            position="relative"
            _hover={{
              color: "#32B44A", // Change color on hover
              _after: {
                width: "100%", // Underline grows on hover
              },
            }}
            _after={{
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "0%",
              height: "2px",
              backgroundColor: "#32B44A",
              transition: "width 0.3s ease",
            }}
          >
            Privacy Policy
          </Link>
        </ListItem>
        <ListItem>
          <Link
            color={textColor}
            href={termsAndConditionsUrl}
            target="_blank"
            fontWeight="500"
            position="relative"
            _hover={{
              color: "#32B44A", // Change color on hover
              _after: {
                width: "100%", // Underline grows on hover
              },
            }}
            _after={{
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "0%",
              height: "2px",
              backgroundColor: "#32B44A",
              transition: "width 0.3s ease",
            }}
          >
            Terms & Conditions
          </Link>
        </ListItem>
        </List>
        <List display="flex" gap="20px" flexDirection={{ base: "column", md: "row" }}>
        <ListItem>
          <Link
            color={textColor}
            href={shippingPolicyUrl}
            target="_blank"
            fontWeight="500"
            position="relative"
            _hover={{
              color: "#32B44A", // Change color on hover
              _after: {
                width: "100%", // Underline grows on hover
              },
            }}
            _after={{
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "0%",
              height: "2px",
              backgroundColor: "#32B44A",
              transition: "width 0.3s ease",
            }}
          >
            Shipping Policy
          </Link>
        </ListItem>
        <ListItem>
          <Link
            color={textColor}
            href={cancellationPolicyUrl}
            target="_blank"
            fontWeight="500"
            position="relative"
            _hover={{
              color: "#32B44A", // Change color on hover
              _after: {
                width: "100%", // Underline grows on hover
              },
            }}
            _after={{
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "0%",
              height: "2px",
              backgroundColor: "#32B44A",
              transition: "width 0.3s ease",
            }}
          >
            Cancellation Policy
          </Link>
        </ListItem>
        </List>
      </List>
    </Flex>
  );
}
