import { Box, Button, Center, Flex, Grid, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import labels from "components/label/lables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from '../../config.json';
const isLocal = config.isLocal;

function Purchase() {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const pageSize = 10;

  const fetchOrders = async (pageNumber = 1) => {
    setLoading(true);
    try {
      if (isLocal) {
        const response = await fetch('/data/orders.json');
        if (!response.ok) throw new Error('Failed to fetch orders');
        const data = await response.json();
        setOrders(data.orders);
        setTotalOrders(data.total);
      } else {
        const token = localStorage.getItem("authToken");
        const response = await fetch(`${config.API}/order/get/all?page=${pageNumber}&pageSize=${pageSize}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (!response.ok) {
          if (response.status === 401) {
            localStorage.removeItem("authToken");
            window.location.href = "/user/login";
            return;
          }
          throw new Error('Failed to fetch orders from the API');
        }
        const data = await response.json();
        setOrders(data.orders);
        setTotalOrders(data.total);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage]);

  const updateOrderStatus = async (orderId, newStatus) => {
    if (!isLocal) {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(`${config.API}/order/update/status`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ orderId, status: newStatus })
        });
        if (!response.ok) {
          throw new Error('Failed to update order status');
        }
        const updatedOrder = await response.text();
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order.orderId === orderId ? { ...order, orderStatus: updatedOrder.status } : order
          )
        );
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    }
  };

  const totalPages = Math.ceil(totalOrders / pageSize);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleViewOrder = (orderId, orderDetails) => {
    navigate(`/user/order/get/${orderId}`, { state: { orderDetails } });
  };

  const textColor = useColorModeValue("gray.800", "gray.200");
  const amountColor = useColorModeValue("gray.800", "gray.200");
  const bgColor = useColorModeValue("gray.100", "navy.800");
  const boxBgColor = useColorModeValue("white", "navy.800");
  const buttonColor = useColorModeValue('#32B44A', '#32B44A');
  const headerColor = useColorModeValue("#32B44A", "#32B44A");

  const renderPageButtons = () => {
    const pageButtons = [];

    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <Button
            key={i}
            onClick={() => handlePageChange(i)}
            variant={i === currentPage ? "solid" : "outline"}
            colorScheme="green"
            color={i === currentPage ? "white" : textColor}
            backgroundColor={i === currentPage ? "green.700" : "transparent"}
            _hover={{
              bg: i === currentPage ? "green.700" : "green.500",
              color: "white"
            }}
            mx={1}

          >
            {i}
          </Button>
        );
      }
    } else {
      if (currentPage > 2) {
        pageButtons.push(
          <Button
            key={1}
            onClick={() => handlePageChange(1)}
            variant="outline"
            colorScheme="green"
            mx={1}
          >
            1
          </Button>
        );
        if (currentPage > 3) {
          pageButtons.push(
            <Text key="start-ellipsis" mx={1}>
              ...
            </Text>
          );
        }
      }
      for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages, currentPage + 1); i++) {
        pageButtons.push(
          <Button
            key={i}
            onClick={() => handlePageChange(i)}
            variant={i === currentPage ? "solid" : "outline"}
            colorScheme="green"
            color={i === currentPage ? "white" : buttonColor}
            _hover={{ bg: "green.700", color: "white" }}
            mx={1}
          >
            {i}
          </Button>
        );
      }
      if (currentPage < totalPages - 1) {
        if (currentPage < totalPages - 2) {
          pageButtons.push(
            <Text key="end-ellipsis" mx={1}>
              ...
            </Text>
          );
        }
        pageButtons.push(
          <Button
            key={totalPages}
            onClick={() => handlePageChange(totalPages)}
            variant="outline"
            colorScheme="green"
            mx={1}
          >
            {totalPages}
          </Button>
        );
      }
    }

    return pageButtons;
  };

  return (
    <Box p={{ base: 3, md: 5 }} bg={bgColor} minHeight="100vh" mt="80px">
      {loading ? (
        <Center minHeight="50vh">
          <Spinner
            thickness="4px"
            speed="0.75s"
            color="#32B44A"
            size="xl"
          />
        </Center>
      ) : (
        <>
          {orders.length > 0 ? (
            orders.map((order, index) => (
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="12px"
                mb="20px"
                bg={boxBgColor}
                p={{ base: 3, md: 5 }}
              >
                <Box bg={headerColor} borderTopRadius="12px" p={{ base: 2, md: 4 }}>
                  <Flex justify="space-between" align="center">
                    <Text fontWeight="bold" color={amountColor} fontSize={{ base: "sm", md: "md" }}>
                      Order ID: {order.orderId}
                    </Text>
                  </Flex>
                </Box>
                <Flex align="center" p={{ base: 2, md: 4 }}>
                  <Box flex="1">
                    <Grid templateColumns="150px 1fr" gap={2} color={textColor}>
                      <Text fontWeight="bold">UserId</Text>
                      <Text whiteSpace="normal" wordBreak="break-word">: {order.userId}</Text>

                      <Text fontWeight="bold">Total Amount</Text>
                      <Text whiteSpace="normal" wordBreak="break-word">: <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>{order.totalPrice}</Text>

                      <Text fontWeight="bold">Total BV</Text>
                      <Text whiteSpace="normal" wordBreak="break-word">: {order.totalBv}</Text>

                      <Text fontWeight="bold">Discount</Text>
                      <Text whiteSpace="normal" wordBreak="break-word">: {order.isDiscounted ? 'Yes' : 'No'}</Text>

                      <Text fontWeight="bold">Order Status</Text>
                      <Text whiteSpace="normal" wordBreak="break-word">: {labels[order.orderStatus]}</Text>

                      <Text fontWeight="bold">Payment Type</Text>
                      <Text whiteSpace="normal" wordBreak="break-word">: {order.paymentType}</Text>
                    </Grid>
                  </Box>
                </Flex>
                <Flex justify="space-between" p={{ base: 2, md: 4 }}>
                  <Button
                    backgroundColor={buttonColor}
                    variant="outline"
                    size={{ base: "sm", md: "md" }}
                    onClick={() => handleViewOrder(order.orderId, order)}
                    _hover={{
                      backgroundColor: buttonColor,
                      borderColor: buttonColor,
                    }}
                  >
                    View Order
                  </Button>
                </Flex>
              </Box>
            ))
          ) : (
            <Text fontSize="lg" color={textColor}>
              No orders available.
            </Text>
          )}

          {/* Pagination */}
          <Flex
            justify="center"
            align="center"
            mt={4}
            wrap="wrap"
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
              variant="outline"
              colorScheme="green"
              mx={1}
              display={{ base: "none", md: "inline-block" }}
            >
              ← Previous
            </Button>

            {renderPageButtons()}

            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
              variant="outline"
              colorScheme="green"
              mx={1}
              display={{ base: "none", md: "inline-block" }}
            >
              Next →
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default Purchase;
