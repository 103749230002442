import {
  Box,
  Button,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Group from "assets/img/dashboards/group.png";
import Card from "components/card/Card.js";
import { Link as RouterLink } from "react-router-dom";

export default function ReferralBanner(props) {
  const { ...rest } = props;
  const textColor = useColorModeValue("secondaryGray.900", "black");
  const subTextColor = useColorModeValue("gray.500", "green.900");
  const buttonBgColor = useColorModeValue("green.400", "green.300");
  const buttonTextColor = useColorModeValue("white", "black");

  // Retrieve the userId from localStorage
  const userId = localStorage.getItem("userId");

  return (
    <Card
      justify="center"
      direction={{ base: "column", md: "row" }}
      w="100%"
      h={{ base: "auto", md: "auto" }}
      bg="linear-gradient(90deg, rgba(189,255,122,1) 0%, rgba(107,206,118,1) 100%)"
      p={{ base: "10px", md: "20px" }}
      {...rest}
    >
      {/* Left side - Text */}
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pr={{ base: "0", md: "20px" }}
        mb={{ base: "20px", md: "0" }}
        textAlign={{ base: "center", md: "left" }}
      >
        <Text
          color={textColor}
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          lineHeight="short"
          mb="10px"
        >
          Share your ID. <br /> To grow your network and earn rewards.
        </Text>

        <Text color={subTextColor} fontSize={{ base: "md", md: "lg" }} mb="20px">
          Earn up to <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>xxx on every Referral
        </Text>

        <Link as={RouterLink} to={`/user/register?referralId=${userId}`}>
          <Button
            bg={buttonBgColor}
            color={buttonTextColor}
            _hover={{ bg: "green.500" }}
            _focus={{ bg: "green.400" }}
            borderRadius="12px"
            px="16px"
            py="12px"
            fontSize={{ base: "sm", md: "md" }}
            width={{ base: "150px", md: "180px" }}
            mx={{ base: "auto", md: "0" }}
          >
            Referral Link!
          </Button>
        </Link>
      </Box>

      {/* Right side - Image */}
      <Box
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="center"
        maxW="100%"
      >
        <Image
          src={Group}
          alt="referral illustration"
          maxW={{ base: "70%", md: "100%" }}
          maxH={{ base: "200px", md: "auto" }}
        />
      </Box>
    </Card>
  );
}
