import { Box, Flex, Icon, SimpleGrid, useColorModeValue, Text } from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import { useEffect, useState } from "react";
import { BiWallet } from "react-icons/bi";
import { MdMonetizationOn } from "react-icons/md";
import { RiBankLine } from "react-icons/ri";
import { HiTrendingUp } from "react-icons/hi"; // New icon for Level Bonus
import { FaRegMoneyBillAlt, FaUserFriends } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import config from '../../config.json';

import { useCart } from "components/CartContext/CartContext";
import MyOrders from "views/admin/default/components/MyOrders";
import PieCard from "views/admin/default/components/PieCard";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";

const isLocal = config.isLocal;

export default function UserReports() {
  const [bonusData, setBonusData] = useState(null);
  const brandColor = useColorModeValue("#32B44A", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "#32B44A");
  const textColor = useColorModeValue("secondaryGray.900", "#32B44A");
  const numberColor = useColorModeValue("secondaryGray.800", "white");
  const { getCart } = useCart();
  const [totalIncome, setTotalIncome] = useState(0);

  useEffect(() => {
    getCart();
    return () => {
      console.log("cart loaded");
    };
  }, []);

  useEffect(() => {
    const fetchIncomeData = async () => {
      try {
        if (!isLocal) {
          const apiUrl = `${config.API}/user/income/get?type=total`;
          const token = localStorage.getItem("authToken");

          const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch income data: ${response.status}`);
          }

          const data = await response.json();

          setBonusData(data);
          setTotalIncome(data.totalIncome);
        }
      } catch (error) {
        console.error("Error fetching income data:", error);
      }
    };

    fetchIncomeData();
  }, []);

  const selfIncome = bonusData?.selfIncome ?? 0;
  const levelIncome = bonusData?.levelIncome ?? 0;
  const referralIncome = bonusData?.referralIncome ?? 0;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Flex container for TotalSpent and PieCard */}
      <Flex direction={{ base: "column", md: "row" }} justify="space-between" mb="20px">
        <Box width={{ base: "100%", md: "70%", lg: "75%" }} mb={{ base: "20px", md: "0" }} mr={{ md: "20px" }}>
          <TotalSpent />
        </Box>
        <Box width={{ base: "100%", md: "30%", lg: "25%" }}>
          <PieCard />
        </Box>
      </Flex>

      {/* Flex container for MiniStatistics and Tasks */}
      <Flex direction={{ base: "column", md: "row" }} mb="20px" gap="20px">
        <Flex width={{ base: "100%", md: "75%" }} direction="column" gap="20px">
          {/* SimpleGrid for MiniStatistics */}
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" width={{ base: "100%" }}>
            <Box width="100%">
              <MiniStatistics
                startContent={
                  <Box
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon w="32px" h="32px" as={FaRegMoneyBillAlt} color={brandColor} />
                  </Box>
                }
                name={
                  <Text color={textColor} fontSize={{ base: "2xl", md: "md" }} fontWeight="bold" textAlign="left">
                    Self Income
                  </Text>
                }
                value={
                  <Text color={numberColor} fontSize={{ base: "2xl", md: "xl" }} fontWeight="bold" textAlign="left" mt="4px">
                    <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>
                    {selfIncome}
                  </Text>
                }
              />
            </Box>

            <Box width="100%">
              <MiniStatistics
                startContent={
                  <Box
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon w="32px" h="32px" as={HiTrendingUp} color={brandColor} />
                  </Box>
                }
                name={
                  <Text color={textColor} fontSize={{ base: "2xl", md: "md" }} fontWeight="bold" textAlign="left">
                    Level Income
                  </Text>
                }
                value={
                  <Text color={numberColor} fontSize={{ base: "2xl", md: "xl" }} fontWeight="bold" textAlign="left" mt="4px">
                    <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>
                    {levelIncome}
                  </Text>
                }
              />
            </Box>

            <Box width="100%">
              <MiniStatistics
                startContent={
                  <Box
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    borderRadius="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon w="32px" h="32px" as={FaUserFriends} color={brandColor} />
                  </Box>
                }
                name={
                  <Text color={textColor} fontSize={{ base: "2xl", md: "md" }} fontWeight="bold" textAlign="left">
                    Referral Income
                  </Text>
                }
                value={
                  <Text color={numberColor} fontSize={{ base: "2xl", md: "xl" }} fontWeight="bold" textAlign="left" mt="4px">
                    <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>
                    {referralIncome}
                  </Text>
                }
              />
            </Box>
          </SimpleGrid>



          {/* WeeklyRevenue below SimpleGrid - Desktop View */}
          <Box width="100%" mb="20px" display={{ base: "none", md: "block" }}>
            <WeeklyRevenue />
          </Box>
        </Flex>

        {/* Tasks on the right */}
        <Box width={{ base: "100%", md: "25%" }}>
          <MyOrders height={{ base: "auto", md: "auto" }} />
        </Box>
      </Flex>

      {/* WeeklyRevenue - Show below Tasks on mobile */}
      <Box width="100%" mt={{ base: "20px", md: "0" }} display={{ base: "block", md: "none" }} mb={{ base: "20px", md: "0" }}>
        <WeeklyRevenue />
      </Box>
    </Box>
  );
}
