import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  IconButton,
  Input,
  Box,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import config from "../../config.json";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@chakra-ui/react";
import { EditIcon } from '@chakra-ui/icons';
import { format } from "date-fns";
const isLocal = config.isLocal;

export default function Banner(props) {

  const { banner} = props;
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const [avatar, setAvatar] = useState('defaultAvatar.jpg'); // Set a default avatar
  const [profilePicture, setProfilePicture] = useState(null);
  // Handler to update the avatar
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatar(URL.createObjectURL(file));
      setProfilePicture(file);
    }
  };
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(userData);
  const [userInfo, setUserInfo] = useState({
    userId: userData.userId,
    fullName: userData.fullName,
    dob: userData.dob,
    phoneNumber: userData.phoneNumber,
    email: userData.email,
    level: userData.level,
    profession: userData.profession,
    referralId: userData.referralId,
  });
  const [isEditing, setIsEditing] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  // const textColorSecondary = "gray.400";
  const editTextColor = useColorModeValue("black", "white");
  const editButtonColor = useColorModeValue("green.500", "green.400");
  const bgColor = useColorModeValue("white", "navy.800");

  // Fetch data from JSON file (simulating your profile.json)
  useEffect(() => {
    const getProfilePicture = async () => {
      const token = localStorage.getItem("authToken");
      const response = await fetch(`${config.API}/document/view/profile/photo?userId=${userData.userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Note: do not set Content-Type header as fetch auto-handles it with FormData
        },
      });
      if (response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/user/login");
      }
      if (response.ok) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setAvatar(imageUrl);
      }

    }

    const getUserData = async () => {
      const token = localStorage.getItem("authToken");
      const response = await fetch(`${config.API}/user/get?userId=${userData.userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/user/login");
      }
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("userData", JSON.stringify(data));
        setUserInfo(data)
      }

    }

    if (isLocal) {
      fetch("/data/profile.json")
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setUserInfo(userData);
          }
        })
        .catch((error) => console.error("Error fetching profile data:", error));
    } else {
      getUserData();
      getProfilePicture();
    }// eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setIsEditing((prev) => !prev);
    setUserInfo(userData);
  };

  const handleEdit = async () => {
    if (isEditing) {
      if (isLocal) {
        await fetch("/data/profile.json");
      } else {
        const token = localStorage.getItem("authToken");

        try {
          const formData = new FormData();
          if (profilePicture) {
            formData.append("photo", profilePicture);
          }
          const userJson = JSON.stringify({
            userId: userInfo.userId,
            fullName: userInfo.fullName,
            dob: userInfo.dob,
            phoneNumber: userInfo.phoneNumber,
            email: userInfo.email,
            level: userInfo.level,
            profession: userInfo.profession,
            referralId: userInfo.referralId,
          });
          formData.append("userInfo", userJson);

          const response = await fetch(`${config.API}/user/update`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`, // Note: do not set Content-Type header as fetch auto-handles it with FormData
            },
            body: formData,
          });

          if (response.status === 401) {
            localStorage.removeItem("authToken");
            navigate("/user/login");
          }

          if (response.ok) {
            const updatedUser = await response.json();
            localStorage.setItem("user", JSON.stringify(updatedUser));
            setUserInfo(updatedUser)
          } else {
            console.error("Failed to update user information.");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
    setIsEditing((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };
  

  return (
    <>
      <Card mb={{ base: "0px", lg: "20px" }} align='center' Width="300px">

        <Box
          bg={`url(${banner})`}
          bgSize='cover'
          borderRadius='16px'
          h='131px'
          w='100%'
        />
        <Box
          position="relative"
          w="187px"
          h="187px"
          mx="auto"
          mt="-43px"
          border="4px solid"
          borderColor={borderColor}
          borderRadius="full"
          overflow="hidden"
        >
          <Avatar
            src={avatar}
            h="100%"
            w="100%"
            border="5px solid #32B44A"

          />

          {/* Edit Icon overlay, visible and clickable only when isEditing is true */}
          <Box
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="rgba(0, 0, 0, 0.4)"
            opacity={isEditing ? 1 : 0} // Show only when isEditing is true
            pointerEvents={isEditing ? "auto" : "none"} // Disable interactions when isEditing is false
            transition="opacity 0.3s"
          >
            <IconButton
              icon={<EditIcon />}
              aria-label="Edit Profile Picture"
              onClick={() => document.getElementById('file-input').click()}
              colorScheme="green"
              size={{ base: "sm", lg: "md" }}
              borderRadius="full"
            />
          </Box>

          {/* Hidden file input */}
          <input
            type="file"
            id="file-input"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleAvatarChange}
          />
        </Box>

        <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
          {userInfo.userId}
        </Text>
      </Card>

      <Card
        mb={{ base: "0px", lg: "20px" }}
        display="flex"
        // align="flex-start"
        bg={bgColor}
        p={5}
        boxShadow="md"
        width={{ base: "100%", lg: "120%" }}
        height="auto"
        left="0"
      // transform={{ base: "none", lg: "translateX(20px)" }}
      >
        <Text fontSize="lg" fontWeight="bold" color="#32B44A" mb={4}>
          PERSONAL INFORMATION
        </Text>

        {isEditing ? (
          <Flex padding={0} direction="column" mt={2} flexWrap="wrap">
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              gap={4}
              width="100%"
              mb={4}
            >
              <Text fontSize="sm" color="#32B44A" minWidth="150px">
                Full Name:
              </Text>
              <Input
                name="fullName"
                value={userInfo.fullName}
                onChange={handleChange}
                color={editTextColor}
                borderColor="#32B44A"
                focusBorderColor="#32B44A"
                _hover={{ borderColor: "#32B44A" }}
                flex="1"
                // height={{ base: "80px", md: "50px" }}
                padding={{ base: "10px", md: "5px" }}
              />
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              gap={4}
              width="100%"
              mb={4}
            >
              <Text fontSize="sm" color="#32B44A" minWidth="150px">
                Date of Birth:
              </Text>
              <Input
                name="dob"
                type="date"
                value={userInfo.dob}
                onChange={handleChange}
                color={editTextColor}
                borderColor="#32B44A"
                focusBorderColor="#32B44A"
                _hover={{ borderColor: "#32B44A" }}
                flex="1"
                padding={{ base: "10px", md: "5px" }}

              />
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              gap={4}
              width="100%"
              mb={4}
            >
              <Text fontSize="sm" color="#32B44A" minWidth="150px">
                Phone Number:
              </Text>
              <Input
                name="phoneNumber"
                value={userInfo.phoneNumber}
                onChange={handleChange}
                color={editTextColor}
                borderColor="#32B44A"
                focusBorderColor="#32B44A"
                _hover={{ borderColor: "#32B44A" }}
                flex="1"
                padding={{ base: "10px", md: "5px" }}

              />
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              gap={4}
              width="100%"
              mb={4}
            >
              <Text fontSize="sm" color="#32B44A" minWidth="150px">
                Email:
              </Text>
              <Input
                name="email"
                value={userInfo.email}
                onChange={handleChange}
                color={editTextColor}
                borderColor="#32B44A"
                focusBorderColor="#32B44A"
                _hover={{ borderColor: "#32B44A" }}
                flex="1"
                padding={{ base: "10px", md: "5px" }}

              />
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              gap={4}
              width="100%"
              mb={4}
            >
              <Text fontSize="sm" color="#32B44A" minWidth="150px">
                Level:
              </Text>
              <Input
                name="level"
                isReadOnly
                cursor="not-allowed"
                value={userInfo.level}
                onChange={handleChange}
                color={editTextColor}
                borderColor="#32B44A"
                focusBorderColor="#32B44A"
                _hover={{ borderColor: "#32B44A" }}
                flex="1"
                padding={{ base: "10px", md: "5px" }}

              />
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              gap={4}
              width="100%"
              mb={4}
            >
              <Text fontSize="sm" color="#32B44A" minWidth="150px">
                Profession:
              </Text>
              <Input
                name="profession"
                value={userInfo.profession}
                onChange={handleChange}
                color={editTextColor}
                borderColor="#32B44A"
                focusBorderColor="#32B44A"
                _hover={{ borderColor: "#32B44A" }}
                flex="1"
                padding={{ base: "10px", md: "5px" }}

              />
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              gap={4}
              width="100%"
              mb={4}
            >
              <Text fontSize="sm" color="#32B44A" minWidth="150px">
                Referral ID:
              </Text>
              <Input
                name="referralId"
                isReadOnly
                cursor="not-allowed"
                value={userInfo.referralId}
                onChange={handleChange}
                color={editTextColor}
                borderColor="#32B44A"
                focusBorderColor="#32B44A"
                _hover={{ borderColor: "#32B44A" }}
                flex="1"
                padding={{ base: "10px", md: "5px" }}

              />
            </Flex>

          </Flex>
        ) : (
          <Flex
            direction="column"
            mt={2}
            bg={bgColor}
            p={{ base: 0, md: 2 }}
            borderRadius="md"
            width="100%"
            flexWrap="wrap"
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              
              gap={1}
              width="100%"
              mb={2}
            >
              <Text fontWeight="semibold" fontSize="md" color="#32B44A" minWidth="150px">
                Full Name:
              </Text>
              <Text color={editTextColor} fontWeight="light" textAlign="left" flex="1" >
                {userInfo.fullName}
              </Text>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              
              gap={1}
              width="100%"
              mb={2}
            >
              <Text fontWeight="semibold" fontSize="md" color="#32B44A" minWidth="150px">
                DOB:
              </Text>
              <Text color={editTextColor} fontWeight="light" textAlign="left" flex="1">
                {format(userInfo.dob, "dd-MM-yyyy")}
              </Text>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              
              gap={1}
              width="100%"
              mb={2}
            >
              <Text fontWeight="semibold" fontSize="md" color="#32B44A" minWidth="150px">
                Phone Number:
              </Text>
              <Text color={editTextColor} fontWeight="light" textAlign="left" flex="1">
                {userInfo.phoneNumber}
              </Text>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              
              gap={1}
              width="100%"
              mb={2}
            >
              <Text
                fontWeight="semibold"
                fontSize="md"
                color="#32B44A"
                minWidth="150px"
                whiteSpace="normal"
                overflowWrap="break-word"
                wordBreak="break-all"
              >
                Email:
              </Text>
              <Text
                color={editTextColor}
                fontWeight="light"
                textAlign="left"
                whiteSpace="normal"
                overflowWrap="break-word"
                wordBreak="break-word"
                flex="1"
              >
                {userInfo.email}
              </Text>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              
              gap={1}
              width="100%"
              mb={2}
            >
              <Text fontWeight="semibold" fontSize="md" color="#32B44A" minWidth="150px">
                Level:
              </Text>
              <Text color={editTextColor} fontWeight="light" textAlign="left" flex="1">
                {userInfo.level}
              </Text>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              
              gap={1}
              width="100%"
              mb={2}
            >
              <Text fontWeight="semibold" fontSize="md" color="#32B44A" minWidth="150px">
                Profession:
              </Text>
              <Text color={editTextColor} fontWeight="light" textAlign="left" flex="1">
                {userInfo.profession}
              </Text>
            </Flex>

            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              
              gap={1}
              width="100%"
              mb={2}
            >
              <Text fontWeight="semibold" fontSize="md" color="#32B44A" minWidth="150px">
                Referral ID:
              </Text>
              <Text color={editTextColor} fontWeight="light" textAlign="left" flex="1">
                {userInfo.referralId}
              </Text>
            </Flex>
          
          </Flex>
        )}

        <Flex justify="flex-start" alignItems="center" mt={4}>
          <Button
            size="sm"
            variant="ghost"
            color={editButtonColor}
            fontWeight="semibold"
            onClick={handleEdit}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
          {isEditing && (
            <Button
              size="sm"
              variant="ghost"
              color={editButtonColor}
              fontWeight="semibold"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          )}
        </Flex>
      </Card>
    </>
  );
}
