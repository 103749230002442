import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  useColorModeValue
} from "@chakra-ui/react";
import { useCart } from 'components/CartContext/CartContext';
import NFT from "components/card/NFT";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from '../../config.json';
const isLocal = config.isLocal;

export default function Marketplace() {
  const navigate = useNavigate();
  const { cartItems, addToCart, setCartItems } = useCart();
  const [products, setProducts] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const inactiveBg = useColorModeValue('white', 'navy.800');
  const activeColor = useColorModeValue('white', 'white');
  const activeBg = useColorModeValue('green.500', 'green.700');
  const inactiveColor = useColorModeValue('green.500', 'green.300');
  const hoverActiveBg = useColorModeValue('green.600', 'green.800');
  const hoverInactiveBg = useColorModeValue('teal.50', 'teal.800');
  const dotsColor = useColorModeValue("gray.400", "gray.500");

  useEffect(() => {
    if (isLocal) {
      fetch("/data/products.json")
        .then((response) => response.json())
        .then((data) => {
          setProducts([...data]);
        })
        .catch((error) => console.error("Error fetching product data from JSON:", error));
    } else {
      fetch(config.API + "/product/getAll")
        .then((response) => response.json())
        .then((data) => {
          setProducts([...data]);
        })
        .catch((error) => console.error("Error fetching product data from API:", error));
    }
  }, []);

  useEffect(() => {
    let sortedProducts = [...products];
    if (sortOption === "price") {
      sortedProducts.sort((a, b) => a.mrp - b.mrp);
    } else if (sortOption === "category") {
      sortedProducts.sort((a, b) => a.productCategory.localeCompare(b.productCategory));
    } else if (sortOption === "name") {
      sortedProducts.sort((a, b) => a.productName.localeCompare(b.productName));
    }
    setProducts(sortedProducts);
    setCurrentPage(1);
  }, [sortOption]);

  const totalPages = Math.ceil(products.length / itemsPerPage);

  const paginate = (page) => {
    if (page < 1 || page > totalPages) {
      console.log("Invalid page:", page);
      return;
    }
    setCurrentPage(page);
    console.log("Navigating to page:", page);
  };

  const currentProducts = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderPaginationButtons = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        Math.abs(i - currentPage) <= 2
      ) {
        pages.push(
          <Box
            key={i}
            as="span"
            border="1px solid"
            borderColor={currentPage === i ? 'teal.500' : 'gray.300'}
            borderRadius="md"
            px="3"
            py="1.5"
            fontSize="sm"
            cursor="pointer"
            bg={currentPage === i ? activeBg : inactiveBg}
            color={currentPage === i ? activeColor : inactiveColor}
            _hover={{
              bg: currentPage === i ? hoverActiveBg : hoverInactiveBg,
            }}
            onClick={() => paginate(i)}
          >
            {i}
          </Box>
        );
      } else if (pages[pages.length - 1]?.key !== "dots") {
        pages.push(
          <Box
            key={`dots-${i}`}
            as="span"
            cursor="default"
            fontSize="sm"
            color={dotsColor}
          >
            ...
          </Box>
        );
      }
    }
    return pages;
  };
  const viewProduct = (product) => {
    navigate(`/user/productdetail`, { state: { product } });
  };

  return (
    <Box pt={{ base: "120px", md: "80px", xl: "80px" }}>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justify="space-between"
        align={{ base: "flex-start", md: "center" }}
        mb="20px"
        px={{ base: "24px", md: "0px" }}
        gap={{ base: "10px", md: "0" }}
      >
        <Flex
          flexDirection={{ base: "column", sm: "row" }}
          align="center"
          gap={{ base: "10px", sm: "20px" }}
          w={{ base: "100%", sm: "auto" }}
        >
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg={useColorModeValue("gray.100", "gray.700")}
              color={useColorModeValue("black", "white")}
              maxW="150px"
              _hover={{ bg: useColorModeValue("gray.200", "gray.600") }}
              w={{ base: "100%", sm: "auto" }}
            >
              {sortOption === "" ? "Sort by..." : sortOption.charAt(0).toUpperCase() + sortOption.slice(1)}
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.800")}
              color={useColorModeValue("black", "white")}
            >
              <MenuItem onClick={() => setSortOption("")} _hover={{ bg: "#32B44A", color: "white" }}>
                Sort by...
              </MenuItem>
              <MenuItem onClick={() => setSortOption("price")} _hover={{ bg: "#32B44A", color: "white" }}>
                Price
              </MenuItem>
              <MenuItem onClick={() => setSortOption("category")} _hover={{ bg: "#32B44A", color: "white" }}>
                Category
              </MenuItem>
              <MenuItem onClick={() => setSortOption("name")} _hover={{ bg: "#32B44A", color: "white" }}>
                Name
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 1 / 2 / 5", "2xl": "1 / 1 / 3 / 3" }}
        >
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px">
            {currentProducts.map((product) => (
              <NFT
                key={product.productCode}
                name={product.productName}
                author={product.author}
                category={product.productCategory}
                price={
                  <>
                    <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>
                    {product.mrp}
                  </>
                }
                discountPrice={
                  <>
                    <span style={{ fontFamily: "'Noto Sans', sans-serif" }}>₹&nbsp;</span>
                    {product.discountPrice}
                  </>
                }
                image={product.productImage}
                viewProduct={() => viewProduct(product)}
                onAddToCart={() => addToCart(product)}
              />
            ))}
          </SimpleGrid>
        </Flex>
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        ></Flex>
      </Grid>
      {/* Pagination Controls */}
      <Flex justify="center" align="center" mt="20px" gap="10px">
        <Button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage <= 1}
          variant="outline"
          colorScheme="teal"
        >
          ← Previous
        </Button>

        {renderPaginationButtons()}

        <Button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage >= totalPages}
          variant="outline"
          colorScheme="teal"
        >
          Next →
        </Button>
      </Flex>
    </Box>
  );
}
