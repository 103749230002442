import { useCart } from "components/CartContext/CartContext";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ProductDetail = () => {
  const location = useLocation();
  const product = location.state?.product || null;
  const { addToCart } = useCart();
  const [quantity, setQuantity] = useState(1);
  const [userLevel, setUserLevel] = useState(null);
  const [toast, setToast] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.userId) {
      setUserLevel(userData.level);
    }
  }, []);

  const incrementQuantity = () => setQuantity((prev) => prev + 1);
  const decrementQuantity = () => quantity > 1 && setQuantity((prev) => prev - 1);

  const handleBuyNow = () => {
    const totalAmount = calculateTotal();

    if (userLevel === "Unknown" && totalAmount < 2000) {
      showToast({
        title: "Purchase not allowed",
        description: "Total amount must be above ₹2000.",
        status: "warning",
        duration: 5000,
        isClosable: true
      });
    } else {
      navigate("/user/payment-process", { state: { product, quantity, fromBuyNow: true } });
    }
  };


  const calculateTotal = () => {
    const totalAmount = product.mrp * quantity;

    if (userLevel === "Unknown" && totalAmount < 2000) {
      return totalAmount; 
    } else if (userLevel === "Unknown" && totalAmount >= 2000) {
      return totalAmount;
    } else {
      return product.discountPrice * quantity;
    }
  };

  // Function to show toast
  const showToast = ({ title, description, status, duration = 5000, isClosable = true }) => {
    setToast({
      title,
      description,
      status,
      duration,
      isClosable
    });

    setTimeout(() => {
      setToast(null); 
    }, duration);
  };


  const handleCloseToast = () => {
    setToast(null); 
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", padding: "40px 10px 10px 10px", alignItems: "stretch" }}>
      {/* Product Content */}
      <div style={{ display: "flex", flex: 1, gap: "30px", justifyContent: "space-between", maxWidth: "1200px", margin: "0 auto", width: "100%", padding: "20px", alignItems: "stretch", height: "100%", }}>
        {/* Image Section */}
        <div style={{ flex: "1", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", }}>
          <img src={product.productImage} alt={product.productName} style={{ width: "100%", height: "auto", objectFit: "cover", borderRadius: "10px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }} />
        </div>

        {/* Product Details */}
        <div style={{ flex: "1", maxWidth: "600px", padding: "10px", display: "flex", flexDirection: "column", }}  className="product-detail-section">
        
          <h3 style={{ fontSize: "22px", fontWeight: "bold" }}>{product.productName}</h3>
          <p style={{ fontSize: "16px", color: "#777", margin: "5px 0" }}>{product.productDesc}</p>
          <p style={{ fontSize: "16px", color: "#777", margin: "5px 0" }}>Benefit: {product.productBenefit}</p>

          <div style={{ fontSize: "18px", margin: "20px 0" }}>
            <span style={{ textDecoration: "line-through", color: "gray" }}>
              <span style={{ fontFamily: "'Noto Sans', sans-serif", margin: "5px 0" }}>₹&nbsp;</span>{product.mrp}.00
            </span>
            <span style={{ fontWeight: "bold", marginLeft: "15px" }}>
              <span style={{ fontFamily: "'Noto Sans', sans-serif", margin: "5px 0" }}>₹&nbsp;</span>{product.discountPrice}.00
            </span>
          </div>

          {/* Quantity and Total */}
          <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
            <div>
              <label htmlFor="quantity" style={{ fontWeight: "bold" }}>Quantity</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button onClick={decrementQuantity} style={{ padding: "5px", cursor: "pointer" }} disabled={quantity <= 1}>-</button>
                <span style={{ margin: "0 15px" }}>{quantity}</span>
                <button onClick={incrementQuantity} style={{ padding: "5px", cursor: "pointer" }}>+</button>
              </div>
            </div>
            <div style={{ marginLeft: "20px", fontSize: "18px", color: "#5b5bf6", fontWeight: "bold" }}>
              Total: <span style={{ fontFamily: "'Noto Sans', sans-serif", fontSize: "15px" }}>₹&nbsp;</span>{calculateTotal()}.00
            </div>
          </div>

          {/* Action Buttons */}
          <div style={{ display: "flex", gap: "15px", marginTop: "5px" }}>
            <button
              onClick={() => {
                addToCart(product, quantity);
                showToast({
                  title: "Item added to cart",
                  description: "Item has been added to your cart.",
                  status: "success",
                  duration: 5000,
                  isClosable: true
                });
              }}
              style={{ backgroundColor: "#5b5bf6", color: "#fff", padding: "10px 20px", border: "none", borderRadius: "5px", cursor: "pointer", flex: "1" }}
            >
              Add to Cart
            </button>
            <button
              onClick={handleBuyNow}
              style={{ backgroundColor: "#28a745", color: "#fff", padding: "10px 20px", border: "none", borderRadius: "5px", cursor: "pointer", flex: "1" }}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
      {/* Toast Notification */}
      {toast && (
        <div
          style={{
            position: "fixed",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: toast.status === "warning"
              ? "#ffe5e5" 
              : toast.status === "success"
                ? "#d4edda" 
                : "#d1ecf1",
            color: toast.status === "warning"
              ? "#a94442" 
              : toast.status === "success"
                ? "#155724"
                : "#0c5460",
            padding: "20px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "300px",
          }}
        >
          <div>
            <h4 style={{ margin: 0, fontWeight: "bold" }}>{toast.title}</h4>
            <p style={{ margin: 0, fontSize: "14px" }}>{toast.description}</p>
          </div>
          {toast.isClosable && (
            <button
              onClick={handleCloseToast}
              style={{
                marginLeft: "10px",
                background: "transparent",
                border: "none",
                color: toast.status === "warning" ? "#a94442" : "green",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              ✕
            </button>
          )}
        </div>
      )}

      {/* Media Query for Mobile Responsiveness */}
      <style jsx>{`
        @media (max-width: 768px) {
          div[style*="flex: 1"] {
            flex-direction: column;
            padding: 0;
            gap: 15px;
          }

          img {
            max-width: 100%;
          }

          h3 {
            font-size: 20px;
          }

          p, span {
            font-size: 14px;
          }

          button {
            padding: 8px 15px;
          }

          div[style*="margin-left: 20px"] {
            margin-left: 0;
          }

          div[style*="min-height: 100vh"] {
            min-height: auto;
            padding-bottom: 100px;
          }
        }
         @media (min-width: 1024px) {
    .product-detail-section {
      margin-top: 60px; /* Adjust as needed for alignment */
    }
  }
      `}</style>

    </div>
  );
};

export default ProductDetail;
